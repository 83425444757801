import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {actionTypes} from '../actionTypes';

const initialState = {
    detail: {},
    expoUpdateStatus: null,
    list: [],
    boothOwner: {},
    expoUser:{},
    allExpoUser:{},
    boothFileDetails:{},
    boothVideoCallData: {},
    meetingSlots: {},
    bookedSlots: {},
    meetingInitiateData: {},
    leaveMeeting:{},
    joinedMeeting: {}
};

export default persistReducer({storage, key: "mixhub-events-expo", whitelist: ["list"]},
    (state = initialState, action) => {
        const {type, payload} = action;
        switch (type) {
            case actionTypes.GET_EXPO_DETAIL_INITIATED: {
                return {...state, loading: true};
            }
            case actionTypes.GET_EXPO_DETAIL_SUCCESS: {
                return {...state, detail: payload, loading: false};
            }
            case actionTypes.GET_EXPO_DETAIL_ERROR: {
                return {...state, loading: false};
            }
            case actionTypes.REQUEST_TO_BOOTH_OWNERS: {
                return {...state, boothOwner: payload, loading: false};
            }

            case actionTypes.GET_EXPO_ALL_LIST_SUCCESS: {
                return {...state, list: [...payload.LARGE, ...payload.MEDIUM, ...payload.SMALL], loading: false};
            }

            case actionTypes.GET_ZONE_EXPO_LIST_SUCCESS: {
                return {...state, list: [...payload.LARGE, ...payload.MEDIUM, ...payload.SMALL], loading: false};
            }

            case actionTypes.GET_EXPO_USER_LIST: {
                return { ...state, expoUser: payload }
            }

            case actionTypes.EXPO_DETAIL_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.EXPO_DETAIL_SUCCESS: {
                return { ...state, loading: false, expoUpdateStatus: payload };
            }
            case actionTypes.EXPO_DETAIL_ERROR: {
                return { ...state, loading: false, expoUpdateStatus: null };
            }
            case actionTypes.GET_ALL_EXPO_USER_LIST: {
                return { ...state, allExpoUser: payload }
            }
            case actionTypes.GET_EXPO_ALL_LIST_THEME_INITIATED: {
                return {...state, loading: true};
            }
            case actionTypes.GET_EXPO_ALL_LIST_THEME_SUCCESS: {
                return {...state, details: payload, loading: false};
            }
            case actionTypes.GET_EXPO_ALL_LIST_THEME_ERROR: {
                return {...state, loading: false};
            }
            case actionTypes.BOOTH_FILE_ACCESS_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.BOOTH_FILE_ACCESS_SUCCESS: {
                return { ...state, loading: false, boothFileDetails: payload };
            }
            case actionTypes.BOOTH_FILE_ACCESS_ERROR: {
                return { ...state, loading: false, boothFileDetails: null };
            }
            case actionTypes.JOIN_BOOTH_VIDEO_CALL_DATA_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.JOIN_BOOTH_VIDEO_CALL_DATA_SUCCESS: {
                return { ...state, loading: false, boothVideoCallData: payload };
            }
            case actionTypes.JOIN_BOOTH_VIDEO_CALL_DATA_ERROR: {
                return { ...state, loading: false, boothVideoCallData: null };
            }
            case actionTypes.LEAVE_BOOTH_VIDEO_CALL_DATA_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.LEAVE_BOOTH_VIDEO_CALL_DATA_SUCCESS: {
                return { ...state, loading: false, boothVideoCallData: payload };
            }
            case actionTypes.LEAVE_BOOTH_VIDEO_CALL_DATA_ERROR: {
                return { ...state, loading: false, boothVideoCallData: null };
            }
            case actionTypes.GET_MEETING_SLOTS_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.GET_MEETING_SLOTS_SUCCESS: {
                return { ...state, loading: false, meetingSlots: payload };
            }
            case actionTypes.GET_MEETING_SLOTS_ERROR: {
                return { ...state, loading: false, meetingSlots: null };
            }
            case actionTypes.BOOK_SELECTED_SLOTS_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.BOOK_SELECTED_SLOTS_SUCCESS: {
                return { ...state, loading: false, bookedSlots: payload };
            }
            case actionTypes.BOOK_SELECTED_SLOTS_ERROR: {
                return { ...state, loading: false, bookedSlots: null };
            }
            case actionTypes.INITIATE_MEETING_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.INITIATE_MEETING_SUCCESS: {
                return { ...state, loading: false, meetingInitiateData: payload };
            }
            case actionTypes.INITIATE_MEETING_ERROR: {
                return { ...state, loading: false, meetingInitiateData: null };
            }
            case actionTypes.JOIN_MEETING_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.JOIN_MEETING_SUCCESS: {
                return { ...state, loading: false, joinedMeeting: payload };
            }
            case actionTypes.JOIN_MEETING_ERROR: {
                return { ...state, loading: false, joinedMeeting: null };
            }
            case actionTypes.LEAVE_MEETING_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.LEAVE_MEETING_SUCCESS: {
                return { ...state, loading: false, meetingInitiateData: {} };
            }
            case actionTypes.LEAVE_MEETING_ERROR: {
                return { ...state, loading: false, leaveMeeting: null };
            }
            default:
                return state;
        }
    })