import { Fragment, useEffect, Suspense } from 'react';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from 'react-toastify';
import { Routes } from './routing/Routes';
import { AuthInit } from './containers/AuthInIt';
import { LocationListener } from './containers/LocationListener';
import { NotificationsListener } from './containers/NotificationsListener';

function App({ store, persistor, basename }) {

  const findParamsFromUrl = async () => {
    const url = new URL(window.location.href);
    const getError = url.searchParams.get('error');
    if (url.searchParams.get('code') && url.searchParams.get('state')) {
      const redirectUrl = window.localStorage.getItem('linkedInReactRedirectUri');
      if (redirectUrl) {
        window.location.href = redirectUrl + '?code=' + url.searchParams.get('code');
      }
    } else if(getError && getError === "user_cancelled_login") {
      window.location.href = window.localStorage.getItem('linkedInReactRedirectUri')
      return
    }
  }

  useEffect(() => {
    findParamsFromUrl();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div>LOAGING APP PersistGate</div>}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <Suspense fallback={<div>LOADING APP</div>}>
          <BrowserRouter basename={basename}>
            <Fragment>
              <AuthInit />
              <Routes />
              <LocationListener />
              <NotificationsListener />
              {/* <AgoraInit /> */}
              <ToastContainer
              // className="notranslate"
              />
            </Fragment>
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
