import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionTypes } from '../actionTypes';

const initialState = {
    details: {}
}

export default persistReducer(
    { storage, key: "mixhub-events-lobby", whitelist: ["seenVideo","userProfileShow"] },
   
    (state = initialState, action) => {
        const { type, payload, code } = action;
        switch (type) {
            case actionTypes.GET_LOBBY_DETAILS_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.GET_LOBBY_DETAILS_SUCCESS: {
                return { ...state, details: payload, loading: false, code };
            }
            case actionTypes.GET_LOBBY_DETAILS_ERROR: {
                return { ...state, loading: false };
            }
            case actionTypes.SET_LOBBY_INTRO_VIDEO_STATUS: {
                return { ...state, seenVideo: true,  };
            }
            case actionTypes.SET_LOBBY_USER_PROFILE_SECTION_STATUS: {
                return {...state, userProfileShow: true}
            }
            // case actionTypes.LOGOUT: {
            //     return { ...state,details:{} ,seenVideo: false, userProfileShow: false};
            // }
            default:
                return state;
        }
    })
