import axios from 'axios';
import * as constants from '../../constants';
import { showToast } from "../../utils";
import { actionTypes } from '../actionTypes';
import { getRoomsChairListListener, saveRoomChair, removeRoomChair, getDeleteRoomChairListener } from "../../utils/firebase";
import { USER_TIME_SPENT_ROOM_JOIN, USER_TIME_SPENT_ROOM_LEAVE } from "../../constants";

const { getUrl } = constants;

//get room details
export const getRoomDetails = (eventId, callback) => async (dispatch) => {
    const { GET_ROOM_DETAILS } = constants;
    dispatch({
        type: actionTypes.GET_ROOM_DETAILS_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_ROOM_DETAILS, eventId));
        dispatch({
            type: actionTypes.GET_ROOM_DETAILS_SUCCESS,
            payload: res.data.data,
            code: res.data.code
        });
        callback(res.data.data);
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ROOM_DETAILS_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

//get room details
/*export const getRoomLink = (eventId, roomName, callback) => async(dispatch) => {
    const { GET_ROOM_LINK } = constants;
    dispatch({
        type: actionTypes.GET_ROOM_LINK_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_ROOM_LINK, eventId));
        dispatch({
            type: actionTypes.GET_ROOM_LINK_SUCCESS,
            payload: res.data.data,
            code: res.data.code
        });
        callback(res.data.data);
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ROOM_LINK_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}*/

export const getChairDetailAction = (roomId, callback) => async (dispatch) => {
    if (roomId) {
        const path = roomId;

        const getChairDetailList = (data) => {
            dispatch({
                type: actionTypes.GET_ROOM_CHAIR_DETAILS,
                roomId,
                data
            });
            if (callback)
                callback(data)
        }
        getRoomsChairListListener(path, getChairDetailList)
    }
}

export const getDeleteChairDetailAction = (roomId, callback) => async (dispatch) => {
    if (roomId) {
        const path = roomId;

        const getChairDetailList = (data) => {
            dispatch({
                type: actionTypes.GET_ROOM_CHAIR_DELETE_DETAILS,
                roomId,
                data
            });
            if (callback)
                callback(data)
        }
        getDeleteRoomChairListener(path, getChairDetailList)
    }
}

export const saveRoomChairAction = (roomId, callback) => async (dispatch) => {
    if (roomId) {

        const saveRoomChairData = (data) => {
            dispatch({
                type: actionTypes.SAVE_ROOM_CHAIR_DETAIL,
                roomId,
                data
            });

            dispatch({
                type: actionTypes.ADD_EVENT_LISTENER,
                payload: () => {
                    removeRoomChair(roomId, data);
                },
                code: ''
            });

            if (callback)
                callback(data)
        }
        saveRoomChair(roomId, saveRoomChairData);
    }
}

export const saveRoomChairJoin = (eventId, roomId, callback) => async (dispatch) => {
    if (roomId) {

        dispatch({
            type: actionTypes.SAVE_ROOM_CHAIR_JOIN_INITIATED
        });

        try {
            const res = await axios.post(getUrl(USER_TIME_SPENT_ROOM_JOIN, eventId, roomId), {});
            // showToast({message: res.data.message, variant: 'success'});

            dispatch({
                type: actionTypes.SAVE_ROOM_CHAIR_JOIN_SUCCESS,
                code: res.data.code,
                payload: res.data.data
            });

            if (callback)
                callback(res.data.data)

        } catch (error) {
            dispatch({
                type: actionTypes.SAVE_ROOM_CHAIR_JOIN_ERROR
            });
        }
    }
}

export const saveRoomChairLeave = (eventId, callback) => async (dispatch) => {
    if (eventId) {

        dispatch({
            type: actionTypes.SAVE_ROOM_CHAIR_LEAVE_INITIATED
        });

        try {
            const res = await axios.post(getUrl(USER_TIME_SPENT_ROOM_LEAVE, eventId), {});
            // showToast({message: res.data.message, variant: 'success'});

            dispatch({
                type: actionTypes.SAVE_ROOM_CHAIR_LEAVE_SUCCESS,
                code: res.data.code,
                payload: res.data.data
            });

            if (callback)
                callback(res.data.data)

        } catch (error) {
            dispatch({
                type: actionTypes.SAVE_ROOM_CHAIR_LEAVE_ERROR
            });
        }
    }
}

export const removeRoomChairAction = (roomName, userRowId, callback) => async (dispatch) => {
    const removeRoomChairData = (data) => {
        dispatch({
            type: actionTypes.REMOVE_ROOM_CHAIR_DETAIL,
            userRowId,
            data
        });

        if (callback)
            callback(data)
    }

    removeRoomChair(roomName, userRowId, removeRoomChairData);
}

export const enableAddNotification = (roomId) => async (dispatch) => {
    dispatch({
        type: actionTypes.ENABLE_ADD_NOTIFICATION,
        payload: roomId
    });
}

export const addOrUpdateNotification = (data) => async (dispatch) => {
    dispatch({
        type: actionTypes.NOTIFICATION_DATA,
        payload: data
    });
}
