import axios from 'axios';
import * as constants from '../../constants';
import { showToast } from "../../utils";
import { actionTypes } from '../actionTypes';

const { getUrl } = constants;

//get lobby details
export const getLobbyDetails = (eventId) => async(dispatch) => {
    const { GET_LOBBY_DETAILS } = constants;
    dispatch({
        type: actionTypes.GET_LOBBY_DETAILS_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_LOBBY_DETAILS, eventId));
        dispatch({
            type: actionTypes.GET_LOBBY_DETAILS_SUCCESS,
            payload: res.data.data,
            // code: res.data.code
        });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_LOBBY_DETAILS_ERROR
        });
        // showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

    export const setLobbyIntroVideoStatus = () => (dispatch) => {
    dispatch({
        type: actionTypes.SET_LOBBY_INTRO_VIDEO_STATUS
    })

}

export const setUserProfileStatus = () => (dispatch) => {
    dispatch({
        type: actionTypes.SET_LOBBY_USER_PROFILE_SECTION_STATUS,
    })
}