import axios from 'axios';
import * as constants from '../../constants';
import { showToast } from "../../utils";
import {
    // getListFromPath,
    getSpeakersWithListenerForOrganizer,
    moveDataFromOnePathToAnother,
    removeFromSpeakersListFirebase,
    // joinFrontStageForAudience,
    // getAudienceWithListenerForOrganizer,
    joinBackStageRequestForAudience,
    getAudienceBackStageRequestsListWithListenerForOrganizer,
    getDataFromPath,
    setDataToPath,
    // removeDataFromPath,
    updateDataOnPath,
    createChangeListenerOnPath,
    joinBackStageForSpeaker,
    getNotificationsListWithListener,
    createRemoveListenerOnPath,
    removeUserFromLocation,
    stageFirebaseEventListner,
    removeAttendeeFromPath,
    addStageMedia,
    getStageUserListWithListener,
    getAllDataByPath,
    getNotificationsListWithListenerForSpeakers,
    allNotification,
    removeDataFromPath
} from '../../utils/firebase';
import { actionTypes } from '../actionTypes';
import store from '../store';
import { setActiveSceneId, setActiveSceneData, setNextSceneData } from '../reducers/broadCastSlice';
import { GET_AGORA_AUTH_RECORDING_TOKEN } from "../../constants";
import { GET_AGORA_AUTH_TOKEN } from "../../constants";

const { getUrl } = constants;

//get stage details
export const getStageDetails = (eventId) => async (dispatch) => {
    const { GET_STAGE_DETAILS } = constants;
    dispatch({
        type: actionTypes.GET_STAGE_DETAILS_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_STAGE_DETAILS, eventId));
        dispatch({
            type: actionTypes.GET_STAGE_DETAILS_SUCCESS,
            payload: res.data.data,
            code: res.data.code
        });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_STAGE_DETAILS_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

//get enter stage details
export const getEnterStageDetails = (eventId, stageId, sessionId, cb) => async (dispatch) => {
    const { GET_ENTER_STAGE_DETAILS } = constants;
    dispatch({
        type: actionTypes.GET_ENTER_STAGE_DETAILS_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_ENTER_STAGE_DETAILS, eventId, stageId, sessionId));
        dispatch({
            type: actionTypes.GET_ENTER_STAGE_DETAILS_SUCCESS,
            payload: res.data.data,
            code: res.data.code
        });

        if (cb) {
            cb(res.data.data, res.data)
        }
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ENTER_STAGE_DETAILS_ERROR
        });

        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

//get enter stage details
export const getArchivedStageDetails = (eventId, stageId, sessionId) => async (dispatch) => {
    const { GET_ARCHIVED_STAGE_DETAILS } = constants;
    dispatch({
        type: actionTypes.GET_ENTER_STAGE_DETAILS_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_ARCHIVED_STAGE_DETAILS, eventId, stageId, sessionId));
        dispatch({
            type: actionTypes.GET_ENTER_STAGE_DETAILS_SUCCESS,
            payload: res.data.data,
            code: res.data.code
        });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ENTER_STAGE_DETAILS_ERROR
        });

        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

//get recording stage details
export const getRecordingStageDetails = (eventId, stageId, sessionId, callback) => async (dispatch) => {
    const { GET_RECORDING_STAGE_DETAILS } = constants;
    dispatch({
        type: actionTypes.TOKEN_VALIDATION_SUCCESS,
        payload: { uid: sessionId, role: "audience", name: "name" },
    })
    const res = await axios.get(getUrl(GET_RECORDING_STAGE_DETAILS, eventId, stageId, sessionId));
    dispatch({
        type: actionTypes.GET_ENTER_STAGE_DETAILS_SUCCESS,
        payload: res.data.data,
        code: res.data.code
    });
    if (callback) {
        callback(res)
    }
}

export const setEnterStageIntroVideoStatus = (stageId) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_ENTER_STAGE_INTRO_VIDEO_STATUS,
        payload: stageId,
    })
}

export const getNewBackStageSpeaker = (path, cb) => dispatch => {

    const saveSpeakersList = (data) => {
        dispatch({
            type: actionTypes.SAVE_NEW_BACK_STAGE_SPEAKER,
            path,
            data
        });

        // dispatch({
        //     type: actionTypes.ADD_EVENT_LISTENER,
        //     payload: () => { removeFromSpeakersListFirebase(`${path}/${data.id}`) },
        //     code: ''
        // });
    }
    getSpeakersWithListenerForOrganizer(path, saveSpeakersList)
    // dispatch({
    //     type: actionTypes.ADD_EVENT_LISTENER,
    //     payload: () => {speakerWithListener.childAdded(); speakerWithListener.childRemoved(); },
    //     code: ''
    // });
}

export const moveDataFromOnePathToAnotherAction = (fromPath, toPath, shouldAttachDisconnectListener) => dispatch => {
    /*dispatch({
        type: actionTypes.ADD_EVENT_LISTENER,
        payload: () => {
            removeFromSpeakersListFirebase(toPath)
        },
        code: ''
    });*/
    moveDataFromOnePathToAnother(fromPath, toPath, shouldAttachDisconnectListener)
}

export const removeUserFromBackStage = (uid, sessionId) => dispatch => {
    // alert(uid);

    const { stage, event } = store.getState();
    const eventId = event.eventData.uid;
    // const stageId = stage.stageDetail.uid;
    // const sessionId = stage.stageDetail.sessionData.uid;
    const databaseValueRefId = [...stage.backStageSpeakers].filter(speaker => speaker.userId == uid)[0]?.id;
    if (databaseValueRefId) {
        // const path = `${eventId}/back-stage-people/${stageId}/${databaseValueRefId}`
        const path = `${eventId}/back-stage-people/${sessionId}/${databaseValueRefId}`
        const removeFromSpeakersList = () => {
            dispatch({
                type: actionTypes.REMOVE_BACK_STAGE_SPEAKER,
                path,
                payload: databaseValueRefId
            });
        }
        removeFromSpeakersListFirebase(path, removeFromSpeakersList)
    }
}

export const getNewFrontStageSpeaker = (path, cb) => dispatch => {
    const { auth: { userData: { uid: userId } }, stage: { stageDetail: { uid: stageId } } } = store.getState();
    const saveSpeakersList = (data) => {
        dispatch({
            type: actionTypes.SAVE_NEW_FRONT_STAGE_SPEAKER,
            path,
            data
        });
        // if (data.userId === userId) {
        //     dispatch({
        //         type: actionTypes.AGORA_CHANGE_ROOM,
        //         payload: `${stageId}-front-stage`,
        //         joiningFrontStage: true
        //     })
        // }
    }
    getSpeakersWithListenerForOrganizer(path, saveSpeakersList);
}

// export const getFrontStageAudience = (path, data, cb) => dispatch => {
export const saveBackStageRequestForAudience = (path, data, cb) => dispatch => {
    const { auth: { userData: { uid: userId } }, stage: { stageDetail: { uid: stageId } }, event: { eventData: { uid: eventId } } } = store.getState();
    const saveAudienceBackStageRequest = (data) => {
        dispatch({
            type: actionTypes.SAVE_NEW_AUDIENCE_BACK_STAGE_REQUEST,
            path,
            data
        });
        if (typeof cb === 'function') {
            cb(data)
        }
        createChangeListenerOnPath(path, (data) => {
            if (data) {
                const { stage: { audienceBackStageRequests } } = store.getState();

                const existingIndex = audienceBackStageRequests.findIndex(a => a.userId === data.userId);
                if (existingIndex > -1) {
                    if (audienceBackStageRequests[existingIndex]?.accepted !== data.accepted) {
                        if (data.accepted === true) {
                            showToast({
                                message: `your request to join backstage has been accepted. Backstage Session will start soon.`,
                                variant: 'success'
                            })

                            // remove attendee from backstage
                            removeAttendeeFromPath(`/${eventId}/back-stage-people/${stageId}/${data.id}`)

                            // remove attendee from frontstage
                            removeAttendeeFromPath(`/${eventId}/front-stage-people/${stageId}/${data.id}`)
                        }
                    }

                    if (audienceBackStageRequests[existingIndex]?.rejected !== data.rejected) {
                        if (data.rejected === true) {
                            showToast({ message: `your request to join backstage has been rejected.`, variant: 'error' })
                        }
                    }
                }

                dispatch({
                    type: actionTypes.SAVE_NEW_AUDIENCE_BACK_STAGE_REQUEST,
                    path,
                    data
                });
            }
        })
        createRemoveListenerOnPath(path, (data) => {
            dispatch({
                type: actionTypes.SAVE_NEW_AUDIENCE_BACK_STAGE_REQUEST,
                path,
                data
            });
        })
    }

    joinBackStageRequestForAudience(path, data, saveAudienceBackStageRequest);
}

export const removeIfExistingDataFromStages = (backStagePath, frontStagePath, userIdToDelete) => (dispatch) => {
    // removeFromSpeakersListFirebase(path, removeFromSpeakersList);
    // if (backStagePath && frontStagePath && userIdToDelete) {
    // const getListCallBack = (dataList) => {
    //     Object.keys(dataList.data).forEach(key => {
    //         if(dataList.data[key].userId === userIdToDelete){
    //             // alert('user found on path >>>>>>> '+ backStagePath)
    dispatch(removeUserFromFrontStage(userIdToDelete))
    dispatch(removeUserFromBackStage(userIdToDelete))
    //         }
    //     })
    // }

    // const listBackStage = getListFromPath(backStagePath, getListCallBack);
    // }


}

export const removeUserFromFrontStage = (uid) => dispatch => {
    const { stage, event } = store.getState();
    const eventId = event.eventData.uid;
    const stageId = stage.stageDetail.uid;
    const databaseValueRefId = [...stage.frontStageSpeakers].filter(speaker => speaker.userId == uid)[0]?.id;
    if (databaseValueRefId) {
        const path = `${eventId}/front-stage-people/${stageId}/${databaseValueRefId}`
        const removeFromSpeakersList = () => {
            dispatch({
                type: actionTypes.REMOVE_FRONT_STAGE_SPEAKER,
                path,
                payload: databaseValueRefId
            });
        }
        removeFromSpeakersListFirebase(path, removeFromSpeakersList)
    }
}

export const joinFrontStage = () => dispatch => {
    dispatch({
        type: actionTypes.JOIN_FRONT_STAGE
    })
}

export const leaveFrontStage = () => dispatch => {
    dispatch({
        type: actionTypes.LEAVE_FRONT_STAGE
    })
}


export const getNewAudienceBackStageRequest = (path, cb) => dispatch => {
    const { auth: { userData: { uid: userId } }, stage: { stageDetail: { uid: stageId, }, audienceBackStageRequests } } = store.getState();
    const saveAudienceRequestList = (data) => {
        // const { auth: { userData: { role: userRole } }, stage: { audienceBackStageRequests } } = store.getState();
        const { stage: { stageDetail: { authUserRole: userRole } }, stage: { audienceBackStageRequests } } = store.getState();
        const existingIndex = audienceBackStageRequests.findIndex(a => a.userId === data.userId);

        if (existingIndex === -1 && !data.removed && !data.changed && !data.accepted && !data.rejected && ((userRole === 'organizer') || (userRole === 'speaker'))) {
            // showToast({ message: `${data.username} has requested to join back stage`, variant: 'info' })
        }
        dispatch({
            type: actionTypes.SAVE_NEW_AUDIENCE_BACK_STAGE_REQUEST,
            path,
            data
        });
    }
    getAudienceBackStageRequestsListWithListenerForOrganizer(path, saveAudienceRequestList);
}

// accept back stage request from audience
export const acceptAudienceBackStageRequest = (eventId, stageId, requestId) => async dispatch => {
    // const backStageSpeakersPath = `/${eventId}/back-stage-people/${stageId}`;
    //         const frontStageSpeakersPath = `/${eventId}/front-stage-people/${stageId}`;
    //         const audienceBackStageRequestsPath = `/${eventId}/audience-back-stage-requests/${stageId}`;
    try {
        const requestPath = `/${eventId}/audience-back-stage-requests/${stageId}/${requestId}`;
        const backStagePath = `/${eventId}/back-stage-people/${stageId}/${requestId}`;
        // moveDataFromOnePathToAnother(requestPath, backStagePath, true);    
        const reqData = await getDataFromPath(requestPath);
        await setDataToPath(backStagePath, { ...reqData, accepted: true });
        await updateDataOnPath(requestPath, { accepted: true });
        dispatch({
            type: actionTypes.SAVE_NEW_AUDIENCE_JOIN_REQUEST_STATUS

        })
        // await removeDataFromPath(requestPath);
        showToast({ message: 'Request accepted. User will join back stage now.', variant: 'success' });
    } catch (error) {
        showToast({ message: 'Some error occurred.', variant: 'error' });
    }

}

// reject back stage request from audience
export const rejectAudienceBackStageRequest = (eventId, stageId, requestId) => async dispatch => {
    try {
        const requestPath = `/${eventId}/audience-back-stage-requests/${stageId}/${requestId}`;
        await updateDataOnPath(requestPath, { rejected: true });
        dispatch({
            type: actionTypes.SAVE_NEW_AUDIENCE_JOIN_REQUEST_STATUS
        })
        showToast({ message: 'Request rejected.', variant: 'success' });
    } catch (error) {
        showToast({ message: 'Some error occurred.', variant: 'error' });
    }
}

export const getNotificationsListWithListenerAction = (path, callback) => async dispatch => {

    const notificationListener = getNotificationsListWithListener(path, callback)
    dispatch({
        type: actionTypes.ADD_EVENT_LISTENER,
        payload: () => {
            notificationListener.childAdded();
            notificationListener.childChanged();
            notificationListener.childRemoved()
        },
        code: ''
    });
}

export const getRemindNotificationsWithListenerAction = (path, callback) => async dispatch => {

    const notificationListener = getNotificationsListWithListener(path, callback)
    // dispatch({
    //     type: actionTypes.ADD_EVENT_LISTENER,
    //     payload: () => {
    //         notificationListener.childAdded();
    //         notificationListener.childChanged();
    //         notificationListener.childRemoved()
    //     },
    //     code: ''
    // });
}

export const getNotificationsListWithListenerActionForSpeaker = (path, callback) => async dispatch => {

    const notificationListener = getNotificationsListWithListenerForSpeakers(path, callback)
    dispatch({
        type: actionTypes.ADD_EVENT_LISTENER,
        payload: () => {
            // notificationListener.childAdded();
            notificationListener.childChanged();
            // notificationListener.childRemoved()
        },
        code: ''
    });
}

export const registerStageListenerActions = (path, callback) => async dispatch => {

    const notificationListener = stageFirebaseEventListner(path, callback)
    dispatch({
        type: actionTypes.ADD_EVENT_LISTENER,
        payload: () => {
            notificationListener.childAdded();
            notificationListener.childChanged();
            notificationListener.childRemoved()
        },
        code: ''
    });
}

export const updateStreamUserInReduxStateAction = (users, callback) => async dispatch => {
    dispatch({
        type: actionTypes.SAVE_STREAM_USERS,
        payload: users,
        code: ''
    });
}

/*export const updateStageMediaListWithListenerAction = (path, callback) => async dispatch => {
    const notificationListener = addStageMedia(path, callback)
    dispatch({
        type: actionTypes.ADD_EVENT_LISTENER,
        payload: () => {
            notificationListener.childAdded();
            notificationListener.childChanged();
            notificationListener.childRemoved()
        },
        code: ''
    });
}*/

export const getStageMediaBroadCast = (data) => dispatch => {
    dispatch({
        type: actionTypes.GET_STAGE_MEDIA_BROADCAST,
        payload: data
    });
}

export const getPdfShareData = (data) => dispatch => {
    dispatch({
        type: actionTypes.GET_PDF_SHARE,
        payload: data
    });
}

//get enter stage details
export const deleteSharedPdf = (eventId, sessionId, cb) => async (dispatch) => {
    const { DELETE_UPLOADED_PDF } = constants;

    try {
        const res = await axios.post(getUrl(DELETE_UPLOADED_PDF, eventId), { session_id: sessionId, event_id: eventId });
        if (cb) {
            cb(res.data.data)
        }
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ENTER_STAGE_DETAILS_ERROR
        });

        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const addStageMediaAction = (data) => dispatch => {
    dispatch({
        type: actionTypes.SAVE_STAGE_MEDIA_LIST,
        payload: data
    })
}

export const stageBroadcastingStatus = (data, cb) => async dispatch => {
    const { UPDATE_STAGE_BROADCASTING_STATUS_URL } = constants;
    try {
        const res = await axios.post(getUrl(UPDATE_STAGE_BROADCASTING_STATUS_URL, data.eventId, data.uid), { 
            broadcasting: data.status, 
            isRecordingStarted: data.isRecordingStarted, 
            session_id: data.sessionId,
            session_start_server_time: data.server_time
        });

        if (cb) {
            cb(res);
        }
    } catch (error) {

        if(data.status == 1) {
            removeLiveSessionPath(data.eventId, data.sessionId)
        } else {
            updateLiveSessionPath(data.eventId, data.sessionId)
        }
        // showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred in update session' });
    }
    /*dispatch({
        type: actionTypes.SAVE_STAGE_BROADCASTING_STATUS,
        payload: res.data.data
    })*/
}

export const getStreamPlaybackId = (data, cb) => async dispatch => {
    const { GET_STREAM_PLAYBACK_ID } = constants;
    const res = await axios.post(getUrl(GET_STREAM_PLAYBACK_ID, data.eventId, data.uid), { session_id: data.sessionId });
    /*dispatch({
        type: actionTypes.SAVE_STAGE_BROADCASTING_STATUS,
        payload: res.data.data
    })*/
    if (cb) {
        cb(res);
    }
}


// get stage user list
export const getStageUserList = (roomName, cb) => async (dispatch) => {
    if (roomName) {
        const path = roomName;
        let userList = [];
        getStageUserListWithListener(path, (data) => {
            if (data && data.uid) {
                if (data.added) {
                    userList = [...userList, data];
                } else if (data.removed) {
                    userList = userList.filter((user) => user.uid !== data.uid);
                }
            }
            dispatch({
                type: actionTypes.GET_STAGE_USER_LIST,
                payload: { userList, roomName }
            });
            if (cb) {
                cb(data)
            }
        })
    }
}

// get all stage user list
export const getAllStageUserList = (roomName, cb) => async (dispatch) => {
    if (roomName) {
        const path = roomName;
        let userList = [];
        getAllDataByPath(path, (data) => {
            if (data && data.id) {
                for (const property in data) {
                    if (property && property !== "id") {
                        let roomData = data[property];
                        for (const val in roomData) {
                            if (val) {
                                userList.push(roomData[val]);
                            }
                        }
                    }
                }
            }
            dispatch({
                type: actionTypes.GET_ALL_STAGE_USER_LIST,
                payload: { userList, roomName }
            });
            if (cb) {
                cb(data)
            }
        })
    }
}

export const getStageRecordingStatus = (eventId, stageId, sessionId) => (dispatch) => {
    const { GET_STAGE_RECORDING_STATUS } = constants;
    try {
        axios.get(getUrl(GET_STAGE_RECORDING_STATUS, eventId, stageId, sessionId)).then(res => {
            dispatch({
                type: actionTypes.GET_STAGE_RECORDING_STATUS,
                payload: res.data.data
            })
        })
    } catch (error) {

    }
}

export const fetchRealTimeRightSideBarStatus = (data) => dispatch => {
    dispatch({
        type: actionTypes.GET_BACK_STAGE_PERMISSION,
        payload: data
    })
}

export const getSessionSpeakerDetails = (eventId, sessionSpeakerId, cb) => async (dispatch) => {
    const { GET_SESSION_SPEAKER_DETAIL } = constants;

    const res = await axios.get(getUrl(GET_SESSION_SPEAKER_DETAIL, eventId, sessionSpeakerId));

    dispatch({
        type: actionTypes.GET_SESSION_SPEAKER_DETAIL,
        payload: res.data.data
    });

    if (cb) {
        cb(res.data.data);
    }
}

export const onSessionSpeakerSave = (eventId, values, cb) => async (dispatch) => {
    const { UPDATE_SESSION_SPEAKER_DETAIL } = constants;

    dispatch({
        type: actionTypes.SESSION_SPEAKER_INITIATED
    });

    try {
        const res = await axios.post(getUrl(UPDATE_SESSION_SPEAKER_DETAIL, eventId), values);
        dispatch({
            type: actionTypes.SESSION_SPEAKER_SUCCESS,
            payload: res.data.data
        });

        if (cb) {
            cb(res.data.data);
        }

        showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.SESSION_SPEAKER_ERROR,
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const onSessionSpeakerDelete = (eventId, scheduleSpeakerId, cb) => async (dispatch) => {
    const { DELETE_SESSION_SPEAKER } = constants;

    const res = await axios.delete(getUrl(DELETE_SESSION_SPEAKER, eventId, scheduleSpeakerId));

    dispatch({
        type: actionTypes.DELETE_SESSION_SPEAKER,
        payload: res.data.data
    });

    if (cb) {
        cb(res.data.data);
    }
}

export const getUpdatedNewSpeakerList = (eventId, stageId, sessionId, cb) => async (dispatch) => {
    const { GET_UPDATED_SESSION_SPEAKERS } = constants;

    const res = await axios.get(getUrl(GET_UPDATED_SESSION_SPEAKERS, eventId, stageId, sessionId));

    dispatch({
        type: actionTypes.GET_UPDATED_SESSION_SPEAKERS,
        payload: res.data.data.sessionSpeakers
    });

    if (cb) {
        cb(res.data.data.sessionSpeakers);
    }
}

export const getActiveSceneId = (eventId, sessionId, callback) => async (dispatch) => {
    let path = `${eventId}/broadcast-scene/${sessionId}`;
    allNotification(path, ({ data }) => {
        if (data && data.activeScene) {
            let scenePath = `${eventId}/stage-scene/${sessionId}/${data.activeScene}`;
            dispatch(setActiveSceneId(data.activeScene));
            getDataFromPath(scenePath).then((res) => {
                if (res && res.id) {
                    dispatch(setActiveSceneData(res));

                    let scenePath = `${eventId}/stage-scene/${sessionId}/`;
                    getDataFromPath(scenePath).then((result) => {
                        let list = [];
                        Object.entries(result).map(([id, value]) => {
                            return list.push({ id: id, data: value })
                        })
                        dispatch(setNextSceneData(list.sort((a, b) => parseFloat(a.data.index) - parseFloat(b.data.index)).find(scene => scene.data.index > res.index)))
                    }).catch((err) => {
                    })
                }
            }).catch((err) => {
            })
        }
    })
}

export const getRoomToken = ({ options, isRecording, roomName, eventId, authUid, sessionId }) => async (dispatch) => {
    let url = "";
    let data = {};
    if (isRecording) {
        url = getUrl(GET_AGORA_AUTH_RECORDING_TOKEN, eventId);
        data = {
            channel_name: roomName,
            user_id: sessionId,
        };
    } else {
        url = getUrl(GET_AGORA_AUTH_TOKEN, eventId);
        data = {
            channel_name: roomName,
        };
    }
    const res = await axios.post(url, data);
    return {
        ...options,
        channel: roomName,
        uid: authUid,
        token: res.data.data.token,
    };
};

export const onUpdateStageBackgroundImg = (eventId, stageId, values, cb) => async (dispatch) => {
    const { UPDATE_STAGE_BACKGROUND_IMAGE } = constants;

    const res = await axios.post(getUrl(UPDATE_STAGE_BACKGROUND_IMAGE, eventId, stageId), values);

    dispatch({
        type: actionTypes.UPDATE_STAGE_BACKGROUND_IMAGE,
        payload: res.data.data
    });

    if (cb) {
        cb(res.data.data);
    }
}


const removeLiveSessionPath = async (eventId, sessionId) => {
    const liveSessionPath = `/${eventId}/live-session/`;
    const liveSessionData = await getDataFromPath(liveSessionPath);
    Object.entries(liveSessionData).map(([key, data]) => {
        if(data.sessionId === sessionId && data.sessionStarted) {
            removeDataFromPath(`${liveSessionPath}/${key}`);
        }
    })
}

const updateLiveSessionPath = async (eventId, sessionId) => {
    const liveSessionPath = `/${eventId}/live-session/`;
    const liveSessionData = await getDataFromPath(liveSessionPath);
    Object.entries(liveSessionData).map(([key, data]) => {
        if(data.sessionId === sessionId && !data.sessionStarted) {
            let data = {
                sessionId: sessionId,
                sessionStarted: true,
            }
            updateDataOnPath(`${liveSessionPath}/${key}`, { ...data });
        }
    })
}