/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import axios from "axios";
import { Suspense, lazy, useEffect, useState, useCallback,Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { useRouter } from "../components/customHooks/useRouter";
import Loader from "../components/Loader/Loader";
import { EMOJI, getUrl } from "../constants";
import {
    emojiIcon,
    getChatMessagesWithListener,
    getExpoUserList,
    getStageUserList,
    fetchRealTimeRightSideBarStatus,
    getLiveSchedule
} from "../redux/actions";
import * as actions from '../redux/actions'
import HelpDesk from "../components/HelpDesk/HelpDesk";
import { getAllDataByPath, onUpdateBackStagePermission, saveEventLiveUser, allNotification } from "../utils/firebase";

const timeOut = 300;

const LobbyPage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/Lobby")), timeOut) }));
const EnterStage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/EnterStage")), timeOut) }));
const ArchivedStage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/ArchivedStage")), timeOut) }));
const EnterExpo = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/EnterExpo")), timeOut) }));
const WidgetSetup = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/WidgetSetup")), timeOut) }));
const EnterZonePage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/EnterZone")), timeOut) }));
const ExpoPage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/Expo")), timeOut) }));
const FindMatchPage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/FindMatch")), timeOut) }));
const LeaderBoardPage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/LeaderBoard")), timeOut) }));
const MyMeetingsPage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/MyMeetings")), timeOut) }));
const NetworkingPage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/Networking")), timeOut) }));
const NewMeetingPage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/NewMeeting")), timeOut) }));
const RoomsPage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/Rooms")), timeOut) }));
const Schedule = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/Schedule")), timeOut) }));
const StagesPage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/Stages")), timeOut) }));
const BackStage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/BackStage")), timeOut) }));
const SpeakerBackStage = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/SpeakerBackStage")), timeOut) }));
const Popups = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/Popups")), timeOut) }));
const MyMeetingRoom = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/MyMeetingRoom")), timeOut) }));
const RoomEventPopUp = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/RoomEventPopUp")), timeOut) }));
const FindMatchPopUp = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/FindMatchPopUp")), timeOut) }));


const BasePage = (props) => {

    const dispatch = useDispatch();
    const router = useRouter();

    const [eId, setEId] = useState("");
    const [boothId, setBoothId] = useState("");
    const [sessionId, setSessionId] = useState("");

    const dispatchAction = useCallback((eventId, dispatchedPath) => {
        dispatch(getChatMessagesWithListener(dispatchedPath));
        dispatch(getChatMessagesWithListener(`/${eventId}/messages/event`));
    }, [dispatch]);

    const onUpdatePermission = useCallback((eId, sessionId) => {
        const backStagePath = `/${eId}/backStagePermission/${sessionId}`;
        onUpdateBackStagePermission(backStagePath, (cbData) => {
            if (cbData && cbData.data && cbData.data.sessionId && cbData.data.sessionId === sessionId) {
                dispatch(fetchRealTimeRightSideBarStatus(cbData.data.rightSideBarStatus))
            }
        });
    }, [dispatch]);

    const fetchScheduleLive = useCallback(() => {
        let path = `/${eId}/notifications/BROADCAST`;
        allNotification(path, ({ data }) => {
            if (data && Object.values(data) && Object.values(data).length) {
                let scheduleData = Object.values(data);
                let isLive = scheduleData.filter((e) => e.type === "STAGE_BROADCAST_STARTED");
                if (isLive && isLive[0]) {
                    dispatch(getLiveSchedule(true));
                } else if (isLive && !isLive[0]) {
                    dispatch(getLiveSchedule(false));

                }
            } else {
                dispatch(getLiveSchedule(false));
            }
        })
    }, [dispatch, eId]);

    useEffect(() => {
        const path = router.pathname;
        const splitPath = path.split("/");
        if (splitPath && splitPath.length) {
            if (splitPath[1] !== "undefined") {
                if (splitPath[1] && splitPath[4]) {
                    dispatch(getStageUserList(`/${splitPath[1]}/stagedUser/${splitPath[4]}/`));
                    setSessionId(splitPath[4]);
                    setEId(splitPath[1]);
                }
                if (splitPath[1] && splitPath[3]) {
                    setBoothId(splitPath[3]);
                    // dispatchAction(splitPath[1], `${splitPath[1]}/messages/${splitPath[3]}/stage`);
                    // dispatch(getStageUserList(`/${splitPath[1]}/stagedUser/${splitPath[3]}/`));
                    dispatch(getExpoUserList(`/${splitPath[1]}/expoUser/${splitPath[3]}/`));
                } else if (splitPath[1]) {
                    // dispatchAction(splitPath[1], splitPath[1]);
                }
                setEId(splitPath[1]);

            }

            // setEId(splitPath[1]);
        }
    }, [dispatch, router.pathname]);

    useEffect(() => {
        if (eId) {
            axios.get(getUrl(EMOJI, eId)).then((res) => {
                if (res && res.data && res.data.data && res.data.data.length) {
                    dispatch(emojiIcon(res.data.data));
                }
            }).catch((err) => {
                dispatch(emojiIcon([]));
            });
            fetchScheduleLive();
        }
    }, [dispatch, eId, fetchScheduleLive]);

    useEffect(() => {
        if (eId && props.userData) {
            saveEventLiveUser(props.userData, `${eId}/live-user/${props.userData.uid}`)
        }

    }, [props.userData, eId])

    useEffect(() => {
        if (eId && boothId) {
            dispatchAction(eId, `${eId}/messages/${boothId}/stage`);
        } else if (eId) {
            dispatchAction(eId, eId);
        }
    }, [dispatchAction, eId, boothId]);

    useEffect(() => {
        if (eId && sessionId) {
            const backStagePath = `/${eId}/backStagePermission/${sessionId}`;
            onUpdatePermission(eId, sessionId);
            getAllDataByPath(backStagePath, (cbData) => {
                if (cbData && cbData.userId && cbData.sessionId === sessionId) {
                    dispatch(fetchRealTimeRightSideBarStatus(cbData.rightSideBarStatus));
                }
            });
        }
    }, [dispatch, eId, sessionId, onUpdatePermission]);

    return (
        <Fragment>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route exact path="/:id/lobby" component={LobbyPage} />
                    <Route path="/:id/expo/:zoneId/zone" component={EnterZonePage} />
                    <Route exact path="/:id/expo/:expoId" component={EnterExpo} />
                    <Route path="/:id/expo/:expoId/booth-widget" component={WidgetSetup} />
                    <Route path="/:id/expo" component={ExpoPage} />
                    <Route path="/:id/find-match" component={FindMatchPage} />
                    <Route path="/:id/leader-board" component={LeaderBoardPage} />
                    <Route path="/:id/my-meetings" component={MyMeetingsPage} />
                    <Route path="/:id/networking" component={NetworkingPage} />
                    <Route path="/:id/new-meeting" component={NewMeetingPage} />
                    <Route path="/:id/rooms" component={RoomsPage} />
                    <Route path="/:id/schedule" component={Schedule} />
                    <Route path="/:id/stages/:stageId/:sessionId/backstage" component={BackStage} />
                    <Route path="/:id/stages/:stageId/:sessionId/speakerbackstage" component={SpeakerBackStage} />
                    <Route path="/:id/stages/:stageId/:sessionId" component={EnterStage} />
                    <Route path="/:id/session/:stageId/:sessionId/archived" component={ArchivedStage} />
                    <Route path="/:id/stages" component={StagesPage} />
                    <Route path="/:id/popups" component={Popups} />
                    <Route path="/:id/my-meeting-room" component={MyMeetingRoom} />
                    <Route path="/:id/room-event-popup" component={RoomEventPopUp} />
                    <Route path="/:id/find-match-popup" component={FindMatchPopUp} />
                    <Redirect path="/:id" to="/:id/lobby" />
                </Switch>
                <HelpDesk />
            </Suspense>
        </Fragment>

    );
}

const mapStateToProps = ({ auth, event }) => {
    return {
        userData: auth?.userData,
        eventData: event.eventData
    }
}

const BasePageLayout = connect(mapStateToProps, actions)(BasePage);
export default BasePageLayout;