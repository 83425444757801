import {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NotificationPopup } from '../components/modal/NotificationPopup';
import { notificationCategoryTypes, notificationTypes } from '../constants';
import * as actions from '../redux/actions'
import { deleteDocument } from '../utils/firebase';

// http://localhost:3005/mixhubb-frontend/d80e967b-5123-4b44-a6b0-a741e324ec4e/stages/c9145f19-1d3b-4cf6-9f02-62e3c72ce1da/backstage

// GLOBAL notifications listener
const NotificationsListenerBase = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const [notification, setNotification] = useState(null);
    const [listenerCreated, setListenerCreated] = useState(null);
    
    useEffect(()=>{
        
        let text = props.location.pathname;
        if(props.eventId && props.userId && !text.match("registration") && !listenerCreated){
            setListenerCreated(true);
            getEventNotificationsWithListener()
        }
    }, [props.eventId, props.userId, props.userProfileShow, props.location.pathname])

    const getEventNotificationsWithListener = () => {
            const path = `${props.eventId}/notifications/${notificationCategoryTypes.GLOBAL}`;

            const saveNotificationInList = (data) => {
                // type: notificationTypes.BACK_STAGE_REMIND,
                // deliveryType: notificationDeliveryTypes.POPUP,
                // category: notificationCategoryTypes.GLOBAL,
                // message: 'You are requested at back stage '+props.stageDetails.title,
                // stageId: stageId,
                // userId: uid,
                // timestamp: new Date().getTime(),
                // navLink: `/${eventId}/stages/${stageId}/backstage`
                
                if(data.type === notificationTypes.BACK_STAGE_REMIND && data.userId === props.userId && !data.removed){
                    setNotification(data);
                    setShowPopup(true);
                }

                props.getNotificationItem(data, notificationCategoryTypes.GLOBAL)
            }
        props.getRemindNotificationsWithListenerAction(path, saveNotificationInList)
    }

    const closeCallback = () => {
        deleteDocument(`${props.eventId}/notifications/${notificationCategoryTypes.GLOBAL}/${notification.id}`);
        setNotification(null);
        setShowPopup(false);
    }
    
    const takeMeThereCallBack = () => {
        deleteDocument(`${props.eventId}/notifications/${notificationCategoryTypes.GLOBAL}/${notification.id}`);
        props.history.push(notification.navLink);
        setNotification(null);
        setShowPopup(false);
    }

    return (showPopup && notification) && <NotificationPopup notification={notification} handleClose={closeCallback} onClickNavLink={takeMeThereCallBack} shouldShowNavLink={notification.hasOwnProperty("navLink")} />
}

const mapStateToProps = ({ auth, stage, event, lobby }) => {
    return {  
        userId: auth.userData?.uid,
        stageId: stage.stageDetail.uid,
        eventId: event.eventData?.uid,
        userProfileShow: lobby.userProfileShow,
      }
}

export const NotificationsListener = connect(mapStateToProps, actions)(withRouter(NotificationsListenerBase))