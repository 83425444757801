import { actionTypes } from '../actionTypes';

const initialState = {
    initialTimestamp: new Date().getTime(),
    roomName: "",
    isChatActive: false,
    isNew: false,
    privateChat: {},
    togglePplChat: {},
    chatList: [],
    emoji: [],
    fromStage: "",
    fromBackStage: "",
    fromCommon: "",
    fromExpo: "",
    isNewStageChat: false,
    isStageChatActive: false,
    stageRoomName: "",
    isBackStageChatActive: false,
    isNewBackStageChat: false,
    backStageRoomName: "",
    isNewExpoChat: false,
    isExpoChatActive: false,
    expoRoomName: "",
    togglePublicChat:false,
};


export default function chatReducer(state = initialState, action) {
    const { type, payload, roomName, data } = action;

    switch (type) {
        case actionTypes.GET_MESSAGES_LIST: {
            return { ...state, [roomName]: data };
        }
        case actionTypes.GET_MESSAGES_LIST_ITEM: {

            let isNew = state.isNew;
            let isChatActive = state.isChatActive;
            let fromCommon = state.fromCommon;
            let fromStage = state.fromStage;
            let fromBackStage = state.fromBackStage;
            let isStageChatActive = state.isStageChatActive;
            let isNewStageChat = state.isNewStageChat;
            let stageRoomName = state.stageRoomName;
            let isBackStageChatActive = state.isBackStageChatActive;
            let isNewBackStageChat = state.isNewBackStageChat;
            let backStageRoomName = state.backStageRoomName;
            let fromExpo = state.fromExpo;
            let isExpoChatActive = state.isExpoChatActive;
            let isNewExpoChat = state.isNewExpoChat;
            let expoRoomName = state.expoRoomName;
            let lastPublicChatId = localStorage.getItem("lastPublicMsgId");
            let lastStageMsgId = localStorage.getItem("lastStageMsgId");
            let lastExpoMsgId = localStorage.getItem("lastExpoMsgId");
            let lastBackStageMsgId = localStorage.getItem("lastBackStageMsgId");

            if (data && data.from) {
                if (data.from === "stage") {
                    fromStage = data.from;
                } else if (data.from === "backStage") {
                    fromBackStage = data.from;
                } else if (data.from === "commonChat") {
                    fromCommon = data.from;
                } else if (data.from === "expo") {
                    fromExpo = data.from;
                }
            }
          
            if (data && data.from === "commonChat" && !data.isRemoved) {
                isNew = true;
                isChatActive = lastPublicChatId === data.id ? true : false;
            } else if (data && data.from === "stage" && !data.isRemoved) {
                isNewStageChat = true;
                isStageChatActive = lastStageMsgId === data.id ? true : false;
                stageRoomName = data.roomName;
            } else if (data && data.from === "backStage" && !data.isRemoved) {
                isNewBackStageChat = true;
                isBackStageChatActive = lastBackStageMsgId === data.id ? true : false;
                backStageRoomName = data.roomName;
            } else if (data && data.from === "expo" && !data.isRemoved) {
                isNewExpoChat = true;
                isExpoChatActive = lastExpoMsgId === data.id ? true : false;
                expoRoomName = data.roomName;
            }

            let listArr = state[roomName] ? state[roomName].findIndex(i => i.id === data.id) < 0 ? [...state[roomName], data] : state[roomName] : [data];

            let findIndexVal = listArr && listArr.length && listArr.findIndex((elem) => (data.isRemoved && elem.id === data.id))
            if (data && data.isRemoved && findIndexVal >= 0) {
                listArr.splice(findIndexVal, 1);
            }

            return {
                ...state,
                [roomName]: listArr,
                roomName,
                isNew,
                isChatActive,
                fromStage,
                fromBackStage,
                fromCommon,
                fromExpo,
                isStageChatActive,
                isNewStageChat,
                stageRoomName,
                isNewExpoChat,
                isExpoChatActive,
                expoRoomName,
                isNewBackStageChat,
                isBackStageChatActive,
                backStageRoomName,
            };
        }
        case actionTypes.SET_LOBBY_PERSONAL_CHAT_DETAILS: {
            return { ...state, isNew: false, personalChatRoomDetails: payload };
        }

        case actionTypes.HIDE_CHAT_NOTIFICATION: {
            return { ...state, isChatActive: payload };
        }

        case actionTypes.GET_ALL_PRIVATE_CHAT_NOTIFICATION_LIST: {
            return { ...state, privateChat: payload };
        }

        case actionTypes.TOGGLE_PEOPLE_CHAT: {
            return { ...state, togglePplChat: payload };
        }

        case actionTypes.CHAT_LIST: {
            return { ...state, chatList: payload };
        }

        case actionTypes.EMOJI: {
            return { ...state, emoji: payload };
        }

        case actionTypes.FROM_WHICH_CHAT: {
            return { ...state, from: payload };
        }

        case actionTypes.HIDE_STAGE_CHAT: {
            return { ...state, isStageChatActive: payload };
        }

        case actionTypes.HIDE_EXPO_CHAT: {
            return { ...state, isExpoChatActive: payload };
        }

        case actionTypes.HIDE_BACKSTAGE_CHAT: {
            return { ...state, isBackStageChatActive: payload };
        }

        case actionTypes.TOGGLE_PUBLIC_CHAT: {
            return { ...state, togglePublicChat: payload };
        }

        default:
            return state;
    }
}
