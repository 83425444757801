import { svgComponents } from '../components/common/svgComponents';

export * from './apiUrls'
export * from './notificationTypes';

export const DATE_FORMAT = 'MM-DD-YYYY';
export const TIME_FORMAT = 'HH:mm';

// Social Logins
// export const LINKEDIN_CLIENT_ID = '86hlslt0hn5fmt';
export const LINKEDIN_CLIENT_ID = '77lcd0t3ha7eax';
// export const FACEBOOK_APP_ID = '162875669326686';
export const FACEBOOK_APP_ID = '988907491804504';
// export const GOOGLE_CLIENT_ID = "548509794156-11bh0rhp7cn5bqhmnsh1beouodaln3rt.apps.googleusercontent.com"
export const GOOGLE_CLIENT_ID = '122087183572-e55v52brifhfejepk1igf1kbicr38mmq.apps.googleusercontent.com';
export const TWITTER_CONSUMER_KEY = 'VGMvEDgj57a03pORIkeYP6YF4';
export const TWITTER_CONSUMER_SECRET = 'e46R3rVTpVIFWXiuQjubqoHPxht5rggZEXIXLBKXkLVo6xYti5';

export const NavLinksForMixMenu = (eventId, zones) => {
    let navBar = [
        { title: zones.find((data) => data.key === 'lobby').name, key: "lobby"  , to: `/${eventId}/lobby`, svgImage: svgComponents.Lobby },
        { title: zones.find((data) => data.key === 'schedule').name, key: "schedule"  , to: `/${eventId}/schedule`, svgImage: svgComponents.Schedule },
        { title: zones.find((data) => data.key === 'stage').name, key: "stage"  , to: `/${eventId}/stages`, svgImage: svgComponents.Stage },
        { title: zones.find((data) => data.key === 'rooms').name, key: "rooms"  , to: `/${eventId}/rooms`, svgImage: svgComponents.Rooms },
        { title: zones.find((data) => data.key === 'expo').name, key: "expo"  , to: `/${eventId}/expo`, svgImage: svgComponents.Expo },
        // { title:  zones.find((data) => data.key === 'expo').name, key: "networking", to: `/${eventId}/networking`, svgImage: svgComponents.Networking }
    ]
    return navBar.filter(value => {
        return zones.find(zone => zone.is_active === true && zone.key === value.key)
    })
}

export const EVENT_REGISTRATION_FIELD_TYPES = {
    SHORTANSWER: 'SHORTANSWER',
    MULTISELECT: 'MULTISELECT',
    NUMBER: 'NUMBER',
    DROPDOWN: 'DROPDOWN',
    COUNTRY: 'COUNTRY',
    STATE: 'STATE',
    CITY: 'CITY'
}

export const EVENT_ENTRY_TYPE = {
    REGISTRATION: "REGISTRATION",
    DIRECT_CHECKIN: "DIRECT_CHECKIN",
    INVITE_ONLY: "INVITE_ONLY"
};
export const USER_EVENT_TYPES = {
    GO_BACK_STAGE:'GO_BACK_STAGE'
}

export const EVENT_STATUS = {
    COMPLETED: 'completed',
    UPCOMING: 'upcoming',
    ONGOING: 'on_going',
    DELAYED: 'delayed',
}

export const SCHEDULE_FILTER_ITEM = {
    ALL_DAY: 'All Day',
    ALL_STAGES: 'All Stages'
}

export const ACCEPTED_IMAGE_TYPES = 'image/jpeg, image/png, image/jpg';
export const ACCEPTED_VIDEO_TYPES = 'video/mp4';
export const ACCEPTED_DOCUMENT_TYPES = 'text/csv, application/vnd.ms-excel, .csv, .pdf, .txt, .doc, .docx';

export const DROPZONE_IMAGE_UPLOAD_TITLE_TEXT = 'DROP YOUR IMAGE HERE'
export const DROPZONE_IMAGE_UPLOAD_SUPPORT_TEXT = 'SUPPORT: JPG, JPEG, PNG, (UPTO 5 MB)'

export const DROPZONE_VIDEO_UPLOAD_TITLE_TEXT = 'DROP YOUR VIDEO HERE'
export const DROPZONE_VIDEO_UPLOAD_SUPPORT_TEXT = 'SUPPORT: MP4 (UPTO 50 MB)'

export const DROPZONE_COVER_SUGGESTION = 'For best result upload image of 16:9 ratio';

export const DROPZONE_DOCUMENT_UPLOAD_TITLE_TEXT = 'DROP YOUR FILE HERE';
export const DROPZONE_DOCUMENT_UPLOAD_SUPPORT_TEXT = 'SUPPORT: CSV (UPTO 20 MB)';
export const DOCUMENT_SUPPORT_TEXT = 'SUPPORT: CSV, PDF, TXT, DOC, DOCX, XLS';

export const MEDIA_TYPES = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    DOCUMENT: 'DOCUMENT'
}

// file upload module types
export const FILE_UPLOAD_MODULE_TYPES = {
    BASIC: 'BASIC',
    LANDING_PAGE: 'LANDING_PAGE',
    LOBBY: 'LOBBY',
    STAGE: 'STAGE',
    ROOM: 'ROOM',
    NETWORKING: 'NETWORKING',
    EXPO: 'EXPO',
    SPEAKER: 'SPEAKER_IMPORT',
    ATTENDEE: 'ATTENDEE_IMPORT'
}

export const EVENT_TEMPLATE_TYPES = {
    ...FILE_UPLOAD_MODULE_TYPES
}

export const UPLOAD_FILE_TYPES = {
    IMAGE: 'IMAGE',
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO',
    DOCUMENT: 'DOCUMENT'
}

export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const BASE_URL_FRONTEND = process.env.REACT_APP_MIXHUBB_FRONTEND_URL;

export const WIDGET_COMPONENT_TYPES = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    CAROUSEL: 'CAROUSEL',
    GOTO: 'GOTO',
    HOTSPOT: 'HOTSPOT',
    TICKER: 'TICKER',
    FILE: 'FILE',
    IFRAME: 'IFRAME',
    CAROUSEL_IMAGE: 'CAROUSEL_IMAGE'
}

export const WIDGET_COMPONENT_TYPES_DETAILS = {
    IMAGE: { type: WIDGET_COMPONENT_TYPES.IMAGE, name: 'IMAGE', width: 200, height: 200, top: 10, left: 20, data: { src: "", type: 'paste_url', redirection_type: 'internal', enable_redirection: false } },
    VIDEO: { type: WIDGET_COMPONENT_TYPES.VIDEO, name: 'VIDEO', width: 300, height: 200, top: 20, left: 30, data: { src: "", type: 'paste_url' } },
    CAROUSEL: { type: WIDGET_COMPONENT_TYPES.CAROUSEL, name: 'CAROUSEL', width: 300, height: 300, top: 30, left: 40, data: { images: [], redirection_type: 'internal', enable_redirection: false } },
    GOTO: { type: WIDGET_COMPONENT_TYPES.GOTO, name: 'GO TO', width: 100, height: 30, top: 50, left: 40, data: { text: 'go to', enable_redirection: false, redirection_type: 'internal', type: 'text' } },
    HOTSPOT: { type: WIDGET_COMPONENT_TYPES.HOTSPOT, name: 'HOTSPOT', width: 50, height: 50, top: 50, left: 40, data: { enable_redirection:true,redirection_type:'internal' } }, // attributes:type: text | image
    TICKER: { type: WIDGET_COMPONENT_TYPES.TICKER, name: 'TICKER', width: 300, height: 50, top: 50, left: 40, data: { text: 'please configure text in config', speed: 5 } },
    FILE: { type: WIDGET_COMPONENT_TYPES.FILE, name: 'FILE', width: 300, height: 50, top: 50, left: 40, data: { url: '', src: "", type: 'paste_url', } },
    IFRAME: { type: WIDGET_COMPONENT_TYPES.IFRAME, name: 'IFRAME', width: 300, height: 300, top: 50, left: 40, data: { url: '' } },
    CAROUSEL_IMAGE: { name: 'CAROUSEL IMAGE', url: "",  data: {type: 'paste_url', src: "", enable_redirection: false, redirection_type: 'internal' } }
}

export const WIDGET_COMPONENTS_INPUT_TYPES = {
    IMAGE: { has_url: true, has_file_selection: true, has_redirection: true },
    VIDEO: { has_url: true },
    CAROUSEL: { image_has_redierction: true, select_images: true },
    GOTO: { has_text: true, has_redirection: true, select_image: true },
    HOTSPOT: { has_redirection: true }, // attributes:type: text | image
    TICKER: { has_text: true, has_time: true },
    FILE: { has_thumb: true, has_url: true, has_file_selection: true },
    IFRAME: { has_url: true }
}