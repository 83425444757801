import { actionTypes } from '../actionTypes';

const initialState = {
    agoraInit: false,
    localVideoContainer: '',
    isMicEnabled : true,
    isCameraEnabled : true,
    virtualBackground : {},
}

const agora = (state = initialState, action) => {
    const { type, payload,joiningFrontStage } = action;
    switch (type) {
        case actionTypes.GET_AGORA_CLIENT: {
            return { ...state, agoraClient: payload, agoraInit: true };
        }
        case actionTypes.REINIT_AGORA_CLIENT: {
            return { ...state, agoraClient: null, agoraInit: false };
        }
        case actionTypes.AGORA_CHANGE_ROOM: {
            return { ...state, roomName: payload, joinRoom: true,joiningFrontStage:joiningFrontStage };
        }
        case actionTypes.SET_IS_AGORA_READY: {
            return { ...state, isAgoraReady: payload };
        }
        case actionTypes.SET_CAMS: {
            return { ...state, cams: payload };
        }
        case actionTypes.SET_MICS: {
            return { ...state, mics: payload };
        }
        case actionTypes.SET_CURRENT_CAM: {
            return { ...state, currentCam: payload };
        }
        case actionTypes.SET_CURRENT_MIC: {
            return { ...state, currentMic: payload };
        }
        case actionTypes.SET_LOCAL_TRACKS: {
            return { ...state, localTracks: payload };
        }
        case actionTypes.SET_PREVIEW_TRACKS: {
            return { ...state, previewTracks: payload };
        }
        case actionTypes.SET_LIVE_TRACKS: {
            return { ...state, liveTracks: payload };
        }
        case actionTypes.SET_PREVIEW_CLIENT: {
            return { ...state, previewClient: payload };
        }
        case actionTypes.SET_LIVE_CLIENT: {
            return { ...state, liveClient: payload };
        }

        case actionTypes.SET_LOCAL_CLIENT: {
            return { ...state, backstageClient: payload };
        }

        case actionTypes.SET_VIRTUAL_BACKGROUND: {
            return { ...state, virtualBackground: payload };
        }
        /*case actionTypes.SET_SCREEN_SHARE_PREVIEW_TRACKS: {
            return { ...state, previewScreenTracks: payload };
        }
        case actionTypes.SET_SCREEN_SHARE_LIVE_TRACKS: {
            return { ...state, liveScreenTracks: payload };
        }*/
        case actionTypes.SET_AGORA_FUNCTIONS: {
            return { ...state, ...payload };
        }
        case actionTypes.SET_AGORA_LOCAL_VIDEO_CONTAINER: {
            return { ...state, localVideoContainer: payload };
        }
        case actionTypes.SET_AGORA_REMOTE_VIDEO_CONTAINER: {
            return { ...state, remoteVideoContainer: payload };
        }
        case actionTypes.SET_SHOULD_AGORA_INIT_RENDER: {
            return { ...state, shouldAgoraInItRender: payload };
        }
        case actionTypes.TOGGLE_MIC_ENABLED: {
            return { ...state, isMicEnabled: !state.isMicEnabled };
        }
        case actionTypes.TOGGLE_CAMERA_ENABLED: {
            return { ...state, isCameraEnabled: !state.isCameraEnabled };
        }        
        default:
            return state;
    }
}
export default agora;