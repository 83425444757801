import { Helmet } from 'react-helmet';

export default function MetaData({ metaData }) {

    return (
        <Helmet>
            <title className='notranslate'>{metaData && metaData.title ? metaData.title : ""}</title>
            <meta name="description" content={metaData && metaData.description ? metaData.description : ""} />
            <link rel="canonical" href={metaData && metaData.url ? metaData.url : ""} />
            <meta property="og:description" content={metaData && metaData.description ? metaData.description : ""} />
            <meta property="og:image" content={metaData && metaData.image ? metaData.image : ""} />
            <meta property="og:title" content={metaData && metaData.title ? metaData.title : ""} />
            <link rel="preload" href={metaData && metaData.image ? metaData.image : ""} as="image"></link>
        </Helmet>
    );
}