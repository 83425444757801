import { actionTypes } from "../redux/actionTypes";
// import { useLocation, useParams } from 'react-router-dom'

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    res => {
      if (res.data.code === 'requires_registration' && (!window.location.href.includes('/registration') && !window.location.href.includes('/embedded-registration'))) {
        const {
          event: { eventId }
        } = store.getState();

        store.dispatch({
          type: actionTypes.LOGOUT
        })
        // window.location.href = `${window.location.origin}/${eventId}/registration`;
      }

      return Promise.resolve(res);
    },
    err => {
      if ((err.response?.data?.code === 'unauthorized' || err.response?.data?.code === 'requires_registration')) {
        store.dispatch({
          type: actionTypes.LOGOUT
        })
        // window.location.href = './registration';
      }
      if ((err.response?.data?.code === 'event_expired')) {
        store.dispatch({
          type: actionTypes.LOGOUT
        })
        const {
          event: { eventId }
        } = store.getState();
        // window.location.href = `/${eventId}/event-expired`;
      }

      return Promise.reject(err);
    }
  );
}