import React from "react";
import { Modal, Button } from "react-bootstrap";
import { VscClose} from "react-icons/vsc";

export const NotificationPopup = React.memo(({ handleClose, notification, onClickNavLink, shouldShowNavLink }) =>  {
    return (
        <>
            <Modal className="ModalCommonInfo SchedulewMeeting" show={true} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title><h4>{notification.message}</h4></Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <div className="MeetingNewSetup">
                        <div className="DayScheduleTime DayMeetingTimeSetup">
                        <div className="form-group">
                                <Button className="CloseModal btn GrayButtonInfo btnFullBtn" onClick={handleClose}>
                                    Close
                                </Button>
                            </div>
                            {shouldShowNavLink && <div className="form-group">
                                <Button className="btn BlueColor btnFullBtn" onClick={onClickNavLink} >
                                    Take me there
                                </Button>
                            </div>}
                                                   
                        </div>
                    </div>
                </Modal.Body>

                <div className="ModalClose">
                    <Button className="CloseModal" onClick={handleClose}>
                        <VscClose />
                    </Button>
                </div>

            </Modal>

        </>
    );
})

