import { actionTypes } from '../actionTypes';

const initialState = {
    initialTimestamp: new Date().getTime(),
    list: [],
    userRespondedQna: {},
    isQnAActive: true,
    isNewQnA: true,
    moduleId: ""
}

export default function qNaReducer(state = initialState, action) {
    const { type, payload, data } = action;

    switch (type) {
        case actionTypes.GET_QUESTIONS_LIST: {
            let isNewQnA = state.isNewQnA;
            let isQnAActive = state.isQnAActive;
            let moduleId;
            let lastQnaStageMsgId = localStorage.getItem("lastQnaStageMsgId");
            let lastQnaExpoMsgId = localStorage.getItem("lastQnaExpoMsgId");
            
            if (data && data.qna && !data.isArchieved && !data.isRemoved && data.from === "stage") {
                isNewQnA = true;
                isQnAActive = lastQnaStageMsgId === data.id && !data.changed ? true : false;
                moduleId = data.modalId;
            } else if (data && data.qna && !data.isArchieved && !data.isRemoved && data.from === "expo") {
                isNewQnA = true;
                isQnAActive = lastQnaExpoMsgId === data.id && !data.changed ? true : false;
                moduleId = data.modalId;
            }

            let listArr = data.changed === true ? [...state.list].map(item => item.id === data.id ? data : item) : state.list.findIndex(item => item.id === data.id) < 0 ? [data, ...state.list] : state.list

            let findIndexVal = listArr && listArr.length && listArr.findIndex((elem) => (data.isRemoved && elem.id === data.id))
            if (data && data.isRemoved && findIndexVal >= 0) {
                listArr.splice(findIndexVal, 1);
            }

            return {
                ...state,
                list: listArr,
                isNewQnA,
                isQnAActive,
                moduleId
            };
        }
        case actionTypes.SAVE_USER_RESPONDED_QNA: {
            let userRespondedQna = !state.userRespondedQna.hasOwnProperty(payload.qnaId) ? { ...state.userRespondedQna, [payload.qnaId]: payload.value } : state.userRespondedQna[payload.qnaId] !== payload.value ? { ...state.userRespondedQna, [payload.qnaId]: payload.value } : state.userRespondedQna;
            let stringifyData = JSON.stringify(userRespondedQna);
            localStorage.setItem("userRespondedQna", stringifyData);
            return {
                ...state,
                userRespondedQna: !state.userRespondedQna.hasOwnProperty(payload.qnaId) ? { ...state.userRespondedQna, [payload.qnaId]: payload.value } : state.userRespondedQna[payload.qnaId] !== payload.value ? { ...state.userRespondedQna, [payload.qnaId]: payload.value } : state.userRespondedQna
            };
        }
        case actionTypes.HIDE_QNA_NOTIFICATION: {
            return { ...state, isQnAActive: payload };
        }

        case actionTypes.ADD_MODULE_ID: {
            return { ...state, moduleId: payload };
        }
        default:
            return state;
    }
}
