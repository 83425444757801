import { actionTypes } from '../actionTypes';

const initialState = {
    initialTimestamp: new Date().getTime(),
    list: [],
    userResponedPolls: {},
    isNewPoll: true,
    isPoleActive: true,
    moduleId: ""
}

export default function poolReducer(state = initialState, action) {
    const { type, payload, roomName, data } = action;
    switch (type) {
        case actionTypes.GET_POLLS_LIST_ITEM: {
            let pollList = [];
            Object.entries(data.data).reverse().map(([id, value]) => {
                // eslint-disable-next-line no-unused-expressions
                return pollList.push({ id, ...value })
            });

            let isNewPoll = state.isNewPoll;
            let isPoleActive = state.isNewPoll;
            let moduleId = state.moduleId;

            let lastPollStageMsgId = localStorage.getItem("lastPollStageMsgId");
            let lastPollExpoMsgId = localStorage.getItem("lastPollExpoMsgId");

            if (data && data.data) {
                let values = Object.values(data.data);
                let keys = Object.keys(data.data);

                if (values && values.length) {
                    let objVal = values[values.length - 1];
                    let objKey = keys[keys.length - 1];
                    if (objVal && objVal.from === "stage" && objVal.isPollPublished && !objVal.isArchived) {
                        isNewPoll = true;
                        isPoleActive = lastPollStageMsgId === objKey ? true : false;
                        moduleId = objVal.modalId;
                    } else if (objVal && objVal.from === "expo" && objVal.isPollPublished && !objVal.isArchived) {
                        isNewPoll = true;
                        isPoleActive = lastPollExpoMsgId === objKey ? true : false;
                        moduleId = objVal.modalId;
                    }
                }
            }

            return {
                ...state,
                list: pollList,
                isNewPoll,
                isPoleActive,
                moduleId
            };
        }
        case actionTypes.SAVE_USER_RESPONDED_POLL: {
            let userResponedPolls = !state.userResponedPolls.hasOwnProperty(payload.pollId) ? { ...state.userResponedPolls, [payload.pollId]: payload.value } : state.userResponedPolls[payload.pollId] !== payload.value ? { ...state.userResponedPolls, [payload.pollId]: payload.value } : state.userResponedPolls;
            let data = localStorage.getItem("userResponedPolls");
            let parseData = data ? JSON.parse(data) : {};
            let stringifyData = JSON.stringify([...parseData, ...userResponedPolls]);
            localStorage.setItem("userResponedPolls", stringifyData);
            return { ...state, userResponedPolls: !state.userResponedPolls.hasOwnProperty(payload.pollId) ? { ...state.userResponedPolls, [payload.pollId]: payload.value } : state.userResponedPolls[payload.pollId] !== payload.value ? { ...state.userResponedPolls, [payload.pollId]: payload.value } : state.userResponedPolls };
        }
        case actionTypes.HIDE_POLL_NOTIFICATION: {
            return { ...state, isPoleActive: payload };
        }
        case actionTypes.ADD_MODULE_ID: {
            return { ...state, moduleId: payload };
        }
        default:
            return state;
    }
}
