import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionTypes } from '../actionTypes';

const initialState = {
    list: {},
    stageSeenVideo: {},
    stageDetail: {},
    backStageSpeakers: [],
    frontStageSpeakers: [],
    removedFromBackStage: false,
    audienceBackStageRequests: [],
    stageMedia: [],
    stageMediaList: [],
    audienceJoinRequest: false,
    stageUser: {},
    allStageUser: {},
    stageRecordingData: [],
    backStagePermission: {},
    streamUsers: [],
}

export default persistReducer({ storage, key: "mixhub-events-enter-stage", whitelist: ["stageSeenVideo"] },
    (state = initialState, action) => {
        const { type, payload, data } = action;

        switch (type) {
            case actionTypes.GET_STAGE_DETAILS_INITIATED:
                {
                    return { ...state, loading: true };
                }
            case actionTypes.GET_STAGE_DETAILS_SUCCESS:
                {
                    return { ...state, list: payload, loading: false };
                }
            case actionTypes.GET_STAGE_DETAILS_ERROR:
                {
                    return { ...state, loading: false };
                }
            case actionTypes.GET_ENTER_STAGE_DETAILS_INITIATED:
                {
                    return { ...state, loading: true };
                }
            case actionTypes.GET_ENTER_STAGE_DETAILS_SUCCESS:
                {
                    return { ...state, stageDetail: payload, loading: false };
                }
            case actionTypes.GET_STREAMING_DATA:
            {
                return { ...state, stageDetail: {...state.stageDetail, bg_url: payload.bg_url, sessionData: {...state.stageDetail.sessionData, 'playback_id': payload.playback_id, 'stream_platform': payload.stream_platform}}, loading: false };
            }

            case actionTypes.GET_ENTER_STAGE_DETAILS_ERROR:
                {
                    return { ...state, loading: false };
                }
            case actionTypes.SET_ENTER_STAGE_INTRO_VIDEO_STATUS:
                {
                    return { ...state, stageSeenVideo: { stageIds: [payload] } };
                }
            /*case actionTypes.SAVE_NEW_BACK_STAGE_SPEAKER:
                {
                    return { ...state, backStageSpeakers: data.removed ? state.backStageSpeakers.filter(speaker => speaker.id !== data.id) : state.backStageSpeakers.findIndex(item => item.id === data.id) < 0 ? [...state.backStageSpeakers, data] : state.backStageSpeakers }
                }
            case actionTypes.REMOVE_BACK_STAGE_SPEAKER:
                {
                    return { ...state, backStageSpeakers: [...state.backStageSpeakers.filter(speaker => speaker.id !== payload)] }
                }
            case actionTypes.SAVE_NEW_FRONT_STAGE_SPEAKER:
                {
                    return { ...state, frontStageSpeakers: data.removed ? state.frontStageSpeakers.filter(speaker => speaker.id !== data.id) : state.frontStageSpeakers.findIndex(item => item.id === data.id) < 0 ? [...state.frontStageSpeakers, data] : state.frontStageSpeakers }
                }
            case actionTypes.JOIN_FRONT_STAGE:
                {
                    return { ...state, joinedFrontStage: true }
                }*/
            case actionTypes.LEAVE_FRONT_STAGE:
                {
                    return { ...state, joinedFrontStage: false }
                }
            case actionTypes.SAVE_NEW_AUDIENCE_BACK_STAGE_REQUEST:
                {
                    return {
                        ...state,
                        audienceBackStageRequests: data.removed ? state.audienceBackStageRequests.filter(speaker => speaker.id !== data.id) : state.audienceBackStageRequests.findIndex(item => item.id === data.id) < 0 ? [...state.audienceBackStageRequests, data] : [...[...state.audienceBackStageRequests].filter(item => item.id !== data.id), data],
                        backStageRequestAccepted: (data.accepted && !data.removed) ? true : false, backStageRequestRejected: (data.rejected && !data.removed) ? true : false,
                        audienceJoinRequest: data?.added ? true : false
                    }
                }
            case actionTypes.SAVE_NEW_AUDIENCE_JOIN_REQUEST_STATUS:
                {
                    return { ...state, audienceJoinRequest: false }
                }
            // case actionTypes.LOGOUT: {
            //         return { ...state,stageDetail:{} , stageSeenVideo: {}};
            //     }
            case actionTypes.GET_STAGE_MEDIA_BROADCAST: {
                let stageMedia = [];
                Object.entries(payload.data).forEach(([id, value]) => {

                    return value.isBroadcast === true ? stageMedia.push({ id: id, data: value }) : false
                })
                return { ...state, stageMedia: stageMedia }
            }
            case actionTypes.GET_PDF_SHARE: {
                return { ...state, pdfShare: payload.data }
            }

            case actionTypes.SAVE_STAGE_MEDIA_LIST: {
                let stageMediaList = [];
                Object.entries(payload.data).map(([id, value]) => {
                    return stageMediaList.push({ id: id, data: value })
                })
                return { ...state, stageMediaList: stageMediaList }
            }
            case actionTypes.SAVE_STAGE_BROADCASTING_STATUS: {
                return { ...state, isStageBroadcasting: payload }
            }

            case actionTypes.GET_STAGE_USER_LIST: {
                return { ...state, stageUser: payload }
            }

            case actionTypes.SAVE_STREAM_USERS: {
                return { ...state, streamUsers: payload }
            }


            case actionTypes.GET_ALL_STAGE_USER_LIST: {
                return { ...state, allStageUser: payload }
            }

            case actionTypes.GET_STAGE_RECORDING_STATUS: {
                return { ...state, stageRecordingData: payload }
            }

            case actionTypes.GET_BACK_STAGE_PERMISSION: {
                return { ...state, backStagePermission: payload }
            }

            case actionTypes.GET_UPDATED_SESSION_SPEAKERS: {
                return {
                    ...state, stageDetail: {
                        ...state.stageDetail,
                        sessionData: {
                            ...state.stageDetail.sessionData,
                            sessionSpeakers: payload
                        }
                    }
                }
            }
            
            case actionTypes.UPDATE_STAGE_BACKGROUND_IMAGE: {
                return {
                    ...state, stageDetail: {
                        ...state.stageDetail,
                        bg_url: payload.bg_url
                    }
                }
            }

            default:
                return state;
        }
    })