import { showToast } from '../../utils';
import {  getPollsListWithListener, savePoll, savePollAnswer,hasUserRespondedToPoll } from '../../utils/firebase';
import { actionTypes } from '../actionTypes';

export const getPollsListWithListenerAction = (path, cb) => async (dispatch) => {
    if(path) {
        const savePollsList = (data) => {
            dispatch({
                type: actionTypes.GET_POLLS_LIST_ITEM,
                path,
                data
            });
            if(cb)
                cb(data)
        }
        const listeners = getPollsListWithListener(path, savePollsList);
        dispatch({
            type: actionTypes.ADD_EVENT_LISTENER,
            payload: () => {listeners.childAdded(); listeners.childChanged(); },
            code: ''
        });
    }
}

export const savePollAction = (path, poll, callback) => (dispatch) => {
    try {
        savePoll(path, poll, callback);
        // showToast({'variant':'success', 'message':'Poll created successfully.'});
    } catch (error) {
        showToast({'variant':'error', 'message':'Some error occurred'});
    }   
}

export const savePollAnswerAction = (eventId, stageId, poll, answerIndex) => (dispatch) => {
    try {
        savePollAnswer(eventId, stageId, poll, answerIndex);
        dispatch({
            type: actionTypes.SAVE_USER_RESPONDED_POLL,
            payload: {pollId:poll.id, value: true}
        })
    } catch (error) {
        
    }
}

export const hasUserRespondedAction =  (eventId, stageId, userId, pollId) => async dispatch => {
    //  const res = await hasUserRespondedToPoll(`${eventId}/poll-responses/${stageId}/${pollId}/${userId}`);
    //  dispatch({
    //      type: actionTypes.SAVE_USER_RESPONDED_POLL,
    //      payload: {pollId, value: res}
    //  })
}

export const hidePollNotification = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.HIDE_POLL_NOTIFICATION,
        payload: value
    })
}

export const addPollModuleId = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.ADD_MODULE_ID,
        payload: value
    })
}
