import { combineReducers } from "redux";

import auth from "./auth";
import event from "./event";
import lobby from "./lobby";
import stage from "./stage";
import chats from "./chats";
import agora from "./agora";
import polls from "./polls";
import rooms from "./rooms";
import expo from "./expo";
import listeners from "./listeners";
import schedule from "./schedule";
import question from "./questions";
import notification from "./notification";
import broadCast from "./broadCastSlice";
import feedback from "./feedback";


export const rootReducer = combineReducers({
  auth,
  event,
  lobby,
  stage,
  chats,
  agora,
  polls,
  expo,
  rooms,
  listeners,
  schedule,
  question,
  notification,
  broadCast,
  feedback
});