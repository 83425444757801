import { actionTypes } from '../actionTypes';
import { joinExpo, saveExpoVisitor, saveExpoVisitorCount, removeUserFromLocation, getExpoVisitorListListener ,getExpoBoothOwnerListListener, joinExpoRequest } from "../../utils/firebase";

export const joinUserExpo = (path, userId, userName, designation, company, profileImageUrl, isBoothOwner, callback) => async (dispatch) => {
    try {
        joinExpo(path, {
            uid: userId,
            user_id: userId,
            name: userName,
            designation: designation,
            company: company,
            profile_image_url: profileImageUrl,
            isBoothOwner: isBoothOwner
        }, (dataPath) => {
            dispatch({
                type: actionTypes.ADD_EVENT_LISTENER,
                payload: () => {removeUserFromLocation(dataPath)},
                code: ''
            });

            if(typeof callback === 'function')
                callback()
        })

    } catch (error) {

    }
}

export const getExpoVisitorList = (path, callback) => async (dispatch) => {
    const expoListeners = getExpoVisitorListListener(path, (data) => {
        if (typeof callback === 'function')
            callback(data);
    });
    dispatch({
        type: actionTypes.ADD_EVENT_LISTENER,
        payload: () => {expoListeners.childAdded(); expoListeners.childChanged(); expoListeners.childRemoved()},
        code: ''
    });
}

export const getExpoBoothOwnerList = (path, callback) => async (dispatch) => {
    const expoListeners = getExpoBoothOwnerListListener(path, (data) => {
        if (typeof callback === 'function')
            callback(data);
    });
    dispatch({
        type: actionTypes.ADD_EVENT_LISTENER,
        payload: () => {expoListeners.childAdded(); expoListeners.childChanged(); expoListeners.childRemoved()},
        code: ''
    });
}

export const saveExpoVisitorAction = (path, userId, userName, designation, profileImageUrl, callback) => (dispatch) => {
    try {
        saveExpoVisitor(path, userId, userName, designation, profileImageUrl, callback);
    } catch (error) {

    }
}

export const removeAllListeners = ( callback) => async (dispatch) => {
    dispatch({
        type: actionTypes.REMOVE_ALL_EVENT_LISTENER,
        code: ''
    });
    if(typeof callback === 'function')
        callback()
}

export const requestToBoothOwner = (path, data, boothOwnerData, callback) => async (dispatch) => {
    try {
        joinExpoRequest(path, data, (dataPath) => {
            dispatch({
                type: actionTypes.ADD_EVENT_LISTENER,
                payload: () => {removeUserFromLocation(dataPath)},
                code: ''
            });

            dispatch({
                type: actionTypes.REQUEST_TO_BOOTH_OWNERS,
                payload: {...boothOwnerData, dataPath:dataPath},
                code: ''
            });

            if(typeof callback === 'function')
                callback(dataPath)
        })

    } catch (error) {

    }
}

export const requestFromBoothOwnerToVisitor = (path, data, visitorData, callback) => async (dispatch) => {
    try {
        joinExpo(path, data, (dataPath) => {
            dispatch({
                type: actionTypes.ADD_EVENT_LISTENER,
                payload: () => {removeUserFromLocation(dataPath)},
                code: ''
            });

            dispatch({
                type: actionTypes.REQUEST_TO_VISITORS,
                payload: {...visitorData, dataPath:dataPath},
                code: ''
            });

            if(typeof callback === 'function')
                callback(dataPath)
        })

    } catch (error) {

    }
}

export const addEventListenerToUnsubscribe = (unsubscribeCallBack) => dispatch => {
    dispatch({
        type: actionTypes.ADD_EVENT_LISTENER,
        payload: unsubscribeCallBack,
        code: ''
    });
}

export const addCurrentLocation = (location, callback) => dispatch => {
    dispatch({
        type: actionTypes.ADD_CURRENT_LOCATION,
        payload: location,
        code: ''
    });

    if (callback)
        callback()
}