import { actionTypes } from '../actionTypes';

// get event basic details
export const getAgoraClient = (client) => async (dispatch) => {
    dispatch({
        type: actionTypes.GET_AGORA_CLIENT,
        payload:client
    });
}

export const reInitAgora = () => async (dispatch) => {
    dispatch({
        type: actionTypes.REINIT_AGORA_CLIENT
    });
}

export const changeRoom = (roomName) => (dispatch) => {
    dispatch({
        type: actionTypes.AGORA_CHANGE_ROOM,
        payload: roomName
    });
}

export const setIsAgoraReady = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_IS_AGORA_READY,
        payload: value
    })
}

export const setCams = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_CAMS,
        payload: value
    })
}

export const setMics = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_MICS,
        payload: value
    })
}

export const setCurrentCamera = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_CURRENT_CAM,
        payload: value
    })
}

export const setCurrentMic = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_CURRENT_MIC,
        payload: value
    })
}

export const setLocalTracks = (value) => async (dispatch) => {
    await dispatch({
        type: actionTypes.SET_LOCAL_TRACKS,
        payload: value
    })
}

export const setPreviewTracks = (value) => async (dispatch) => {
    await dispatch({
        type: actionTypes.SET_PREVIEW_TRACKS,
        payload: value
    })
}

export const setLivePreviewTracks = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_LIVE_TRACKS,
        payload: value
    })
}

export const setPreviewClient = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_PREVIEW_CLIENT,
        payload: value
    })
}

export const setLivePreviewClient = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_LIVE_CLIENT,
        payload: value
    })
}

export const setBackStageClient = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_LOCAL_CLIENT,
        payload: value
    })
}

export const setVirtualBackground = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_VIRTUAL_BACKGROUND,
        payload: value
    })
}

/*export const setScreenSharePreviewTracks = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_SCREEN_SHARE_PREVIEW_TRACKS,
        payload: value
    })
}

export const setScreenShareLivePreviewTracks = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_SCREEN_SHARE_LIVE_TRACKS,
        payload: value
    })
}*/

export const setAgoraFunctions = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_AGORA_FUNCTIONS,
        payload: value
    })
}

export const setLocalVideoContainer = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_AGORA_LOCAL_VIDEO_CONTAINER,
        payload: value
    })
}

export const setRemoteVideoContainer = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_AGORA_REMOTE_VIDEO_CONTAINER,
        payload: value
    })
}

export const setShouldAgoraInItRender = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_SHOULD_AGORA_INIT_RENDER,
        payload: value
    })
}

export const toggleMicEnabled = () => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_MIC_ENABLED,
    })
}

export const toggleCameraEnabled = () => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_CAMERA_ENABLED,
    })
}