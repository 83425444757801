import axios from 'axios';
import * as constants from '../../constants';
import { showToast } from "../../utils";
import { actionTypes } from '../actionTypes';
import { getAllDataByPath, getStageUserListWithListener } from "../../utils/firebase";

const { getUrl } = constants;

export const getExpoAllList = (eventId, callback) => async (dispatch) => {
    const { GET_EXPO_ALL_LIST } = constants;
    dispatch({
        type: actionTypes.GET_EXPO_ALL_LIST_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_EXPO_ALL_LIST, eventId));
        if (res.data.data && res.data.data.booth && res.data.data.booth.LARGE) {
            dispatch({
                type: actionTypes.GET_EXPO_ALL_LIST_SUCCESS,
                payload: res.data.data.booth,
                code: res.data.code
            });
        }
        callback(res.data.data);
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EXPO_ALL_LIST_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getExpoBoothThemeList = (eventId) => async (dispatch) => {
    const { GET_BOOTH_LIST_FOR_THEME } = constants;
    dispatch({
        type: actionTypes.GET_EXPO_ALL_LIST_THEME_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_BOOTH_LIST_FOR_THEME, eventId));
        if (res.data.data) {
            dispatch({
                type: actionTypes.GET_EXPO_ALL_LIST_THEME_SUCCESS,
                payload: res.data.data,
            });
        }
        // callback(res.data.data);
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EXPO_ALL_LIST_THEME_ERROR
        });
        // showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getExpoListByZoneId = (eventId, zoneId, callback) => async (dispatch) => {
    const { GET_EXPO_LIST_BY_ZONE } = constants;
    dispatch({
        type: actionTypes.GET_ZONE_EXPO_LIST_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_EXPO_LIST_BY_ZONE, eventId, zoneId));
        if (res.data.data && res.data.data.booth && res.data.data.booth.LARGE) {
            dispatch({
                type: actionTypes.GET_ZONE_EXPO_LIST_SUCCESS,
                payload: res.data.data.booth,
                code: res.data.code
            });
        }
        callback(res.data.data);
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ZONE_EXPO_LIST_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getExpoDetail = (eventId, expoId, callback) => async (dispatch) => {
    const { GET_EXPO_DETAIL } = constants;
    dispatch({
        type: actionTypes.GET_EXPO_DETAIL_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_EXPO_DETAIL, eventId, expoId));
        dispatch({
            type: actionTypes.GET_EXPO_DETAIL_SUCCESS,
            payload: res.data.data,
            code: res.data.code
        });
        callback(res.data.data);
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EXPO_DETAIL_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const onExpoDetailSave = (eventId, expoId, values) => async(dispatch) => {
    const { UPDATE_EXPO_DETAIL } = constants;
    dispatch({
        type: actionTypes.EXPO_DETAIL_INITIATED
    });
    try {
        const res = await axios.post(getUrl(UPDATE_EXPO_DETAIL, eventId, expoId), values);
        dispatch({
            type: actionTypes.EXPO_DETAIL_SUCCESS,
            payload: res.data
        });
        showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.EXPO_DETAIL_ERROR,
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

// get expo user list
export const getExpoUserList = (roomName, cb) => async (dispatch) => {
    if (roomName) {
        const path = roomName;
        let userList = [];
        getStageUserListWithListener(path, (data) => {
            if (data && data.uid) {
                if (data.added) {
                    userList = [...userList, data];
                } else if (data.removed) {
                    userList = userList.filter((user) => user.uid !== data.uid);
                }
            }
            dispatch({
                type: actionTypes.GET_EXPO_USER_LIST,
                payload: { userList, roomName }
            });
            if (cb) {
                cb(data)
            }
        })
    }
}

// get all expo user list
export const getAllExpoUserList = (roomName, cb) => async (dispatch) => {
    if (roomName) {
        const path = roomName;
        let userList = [];
        getAllDataByPath(path, (data) => {
            if (data && data.id) {
                for (const property in data) {
                    if (property && property !== "id") {
                        let roomData = data[property];
                        for (const val in roomData) {
                            if (val) {
                                userList.push(roomData[val]);
                            }
                        }
                    }
                }
            }
            dispatch({
                type: actionTypes.GET_ALL_EXPO_USER_LIST,
                payload: { userList, roomName }
            });
            if (cb) {
                cb(data)
            }
        })
    }
}

/**
 * boothFileAccess
 * @param {*} values | values
 * @returns | file
 */
export const boothFileAccess = (eventId, expoId, values) => async (dispatch) => {
    const {BOOTH_FILE_ACCESS_URL} = constants;
    
    dispatch({
        type: actionTypes.BOOTH_FILE_ACCESS_INITIATED
    });

    try {
        const res = await axios.post(getUrl(BOOTH_FILE_ACCESS_URL, eventId, expoId), values);
        dispatch({
            type: actionTypes.BOOTH_FILE_ACCESS_SUCCESS,
            payload: res.data
        });

        // showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.BOOTH_FILE_ACCESS_ERROR,
        });
        showToast({ variant: 'error', message: error?.response?.data || 'some error occurred' });
    }

}

/**
 * joinBoothVideoCallData
 * @param {*} values | booth owner id and user id
 * @returns promise
 */
export const joinBoothVideoCallData = (eventId, expoId, values) => async (dispatch) => {
    const {JOIN_BOOTH_VIDEO_CALL_DATA_URL} = constants

    dispatch({
        type: actionTypes.JOIN_BOOTH_VIDEO_CALL_DATA_INITIATED
    });

    try {
        const res = await axios.post(getUrl(JOIN_BOOTH_VIDEO_CALL_DATA_URL, eventId, expoId), values);
        dispatch({
            type: actionTypes.JOIN_BOOTH_VIDEO_CALL_DATA_SUCCESS,
            payload: res.data
        });

        // showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.JOIN_BOOTH_VIDEO_CALL_DATA_ERROR,
        });
        showToast({ variant: 'error', message: error?.response?.data || 'some error occurred' });
    }
}

/**
 * leaveBoothVideoCallData
 * @param {*} values | booth owner id and user id
 * @returns promise
 */
export const leaveBoothVideoCallData = (eventId, expoId, values) => async (dispatch) => {
    const {LEAVE_BOOTH_VIDEO_CALL_DATA_URL} = constants

    dispatch({
        type: actionTypes.LEAVE_BOOTH_VIDEO_CALL_DATA_INITIATED
    });

    try {
        const res = await axios.post(getUrl(LEAVE_BOOTH_VIDEO_CALL_DATA_URL, eventId, expoId), values);
        dispatch({
            type: actionTypes.LEAVE_BOOTH_VIDEO_CALL_DATA_SUCCESS,
            payload: res.data
        });

        // showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.LEAVE_BOOTH_VIDEO_CALL_DATA_ERROR,
        });
        showToast({ variant: 'error', message: error?.response?.data || 'some error occurred' });
    }
}

/**
 * getMeetingSlots
 * @param {*} eventId | event id
 * @param {*} userId | attendee or booth owner id
 * @returns promise
 */
export const getMeetingSlots = (eventId, userId, cb=null) => async (dispatch) => {
    const {GET_MEETING_SLOTS_URL} = constants

    dispatch({
        type: actionTypes.GET_MEETING_SLOTS_INITIATED
    });

    try {
        const res = await axios.post(getUrl(GET_MEETING_SLOTS_URL, eventId), userId);
        dispatch({
            type: actionTypes.GET_MEETING_SLOTS_SUCCESS,
            payload: res.data.data
        });
        if(cb) {
            cb(res);
        }
        // showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_MEETING_SLOTS_ERROR,
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

/**
 * bookMeetingSlot
 * @param {*} eventId | event id
 * @param {*} values | values
 * @returns promise
 */
export const bookMeetingSlot = (eventId, values, cb=null) => async (dispatch) => {
    const {BOOK_SELECTED_SLOTS_URL} = constants

    dispatch({
        type: actionTypes.BOOK_SELECTED_SLOTS_INITIATED
    });

    try {
        const res = await axios.post(getUrl(BOOK_SELECTED_SLOTS_URL, eventId), values);
        dispatch({
            type: actionTypes.BOOK_SELECTED_SLOTS_SUCCESS,
            payload: res.data
        });
        if(cb) {
            cb(res);
        }
        if(res.status == "208") {
            showToast({ variant: 'error', message: res.data.message });
        }
        // showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.BOOK_SELECTED_SLOTS_ERROR,
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

/**
 * initiateMeeting
 * @param {*} eventId | event id
 * @param {*} values | values
 * @returns promise
 */
export const initiateMeeting = (eventId, values, cb=null) => async (dispatch) => {
    const {INITIATE_MEETING_URL} = constants

    dispatch({
        type: actionTypes.INITIATE_MEETING_INITIATED
    });

    try {
        const res = await axios.post(getUrl(INITIATE_MEETING_URL, eventId), values);
        dispatch({
            type: actionTypes.INITIATE_MEETING_SUCCESS,
            payload: res.data.data
        });
        if(cb) {
            cb(res);
        }
        // showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.INITIATE_MEETING_ERROR,
        });
        if(cb) {
            cb(error.response);
        }
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

/**
 * joinMeeting
 * @param {*} eventId | event id
 * @param {*} values | values
 * @returns promise
 */
export const joinMeeting = (eventId, values, cb=null) => async (dispatch) => {
    const {JOIN_MEETING_URL} = constants

    dispatch({
        type: actionTypes.JOIN_MEETING_INITIATED
    });

    try {
        const res = await axios.post(getUrl(JOIN_MEETING_URL, eventId), values);
        dispatch({
            type: actionTypes.JOIN_MEETING_SUCCESS,
            payload: res.data.data
        });
        if(cb) {
            cb(res);
        }
        // showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.JOIN_MEETING_ERROR,
        });
        if(cb) {
            cb(error.response);
        }
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

/**
 * leaveMeeting
 * @param {*} eventId | event id
 * @param {*} values | values
 * @returns promise
 */
export const leaveMeeting = (eventId, values, cb=null) => async (dispatch) => {
    const {LEAVE_MEETING_URL} = constants

    dispatch({
        type: actionTypes.LEAVE_MEETING_INITIATED
    });

    try {
        const res = await axios.post(getUrl(LEAVE_MEETING_URL, eventId), values);
        dispatch({
            type: actionTypes.LEAVE_MEETING_SUCCESS,
            payload: res.data.data
        });
        if(cb) {
            cb(res);
        }
        // showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.LEAVE_MEETING_ERROR,
        });
        if(cb) {
            cb(error.response);
        }
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}
