import { useDispatch, useSelector } from "react-redux";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { togglePeopleChat } from "../../redux/actions";
import { svgComponents } from "../common/svgComponents";

const HelpDesk = () => {

    const { customerSupport, userId, eventData } = useSelector(({ event, auth }) => {
        return ({
            customerSupport: event?.eventData?.help_desk_user,
            userId: auth?.userData?.uid, 
            eventData: event?.eventData
        })
    });

    const dispatch = useDispatch();

    return (
        customerSupport && customerSupport.uid && customerSupport.uid !== userId ? <div className="customer-chat"
            onClick={() => {
                dispatch(togglePeopleChat({ ...customerSupport, openPplChat: true, senderId: customerSupport.uid }));
            }}>
            <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-disabled">Help Desk</Tooltip>}>
                {svgComponents.CustomerChat(eventData.navbar_secondary_color ? eventData.navbar_secondary_color : eventData.navbar_primary_color)}
            </OverlayTrigger>
        </div> : null
    )
}
export default HelpDesk;