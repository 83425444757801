import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {actionTypes} from '../actionTypes';

const initialState = {
    list: [],
    currentLocation: null
};

export default persistReducer({storage, key: "mixhub-events-listeners", whitelist: ["list"]},
    (state = initialState, action) => {
        const {type, payload} = action;
        switch (type) {
            case actionTypes.ADD_EVENT_LISTENER: {
                return {...state, list: [...state.list, payload], loading: false};
            }

            case actionTypes.REMOVE_ALL_EVENT_LISTENER: {
                return {...state, list: [], loading: false};
            }

            case actionTypes.ADD_CURRENT_LOCATION: {
                return {...state, currentLocation: payload, loading: false};
            }

            default:
                return state;
        }
    })