// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase, update, set, ref, get, push, serverTimestamp, onValue, query, orderByChild, onChildAdded, increment, onChildChanged, child, remove, onChildRemoved, onDisconnect } from "firebase/database";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import "firebase/database";

import store from "../redux/store";
import { notificationCategoryTypes, notificationDeliveryTypes, notificationTypes, } from "../constants"; //USER_EVENT_TYPES

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DataBASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// export const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6LeRKjscAAAAADnXnp6ziBK2WaBdKRulPiWj5jAt'),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true
// });
/*export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeRKjscAAAAADnXnp6ziBK2WaBdKRulPiWj5jAt'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});*/

export const database = getDatabase(app);
// const analytics = getAnalytics(app);
const connectedRef = ref(database, ".info/connected");
  onValue(connectedRef, (snap) => {
    if (snap.val() === true) {
      console.log("connected");

      if(store.getState().auth.userData) {
        saveEventLiveUser(store.getState().auth.userData, `${store.getState().event.eventData?.uid}/live-user/${store.getState().auth.userData?.uid}`)
      }
    }
  });
// save chat in firebase realtime DB
export function saveChat(path, message, cb) {
  const userName = store.getState().auth.userProfileData && store.getState().auth.userProfileData.name ? store.getState().auth.userProfileData.name : store.getState().auth.userData.name
  const userId = store.getState().auth.userData?.uid;

  push(ref(database, path), {
    ...message,
    userName,
    timestamp: serverTimestamp(),
    userId
  }).then(() => {
    if (cb)
      cb()
  })
}

// get list of messages with listener from firebase
export function getMessagesListWithListener(path, cb) {
  const messageRef = ref(database, path);
  onChildAdded(query(messageRef, orderByChild('timestamp')), (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key });
    }
  })

  onChildRemoved(query(messageRef, orderByChild('timestamp')), (data) => {
    if (cb) {
      cb({ ...data.val(), isRemoved: true, id: data.key });
    }
  })
}

// save qna in firebase realtime DB
export function saveQuestion(path, qna, profileImage, id, from, cb) {
  const userName = store.getState().auth.userData?.name
  const userId = store.getState().auth.userData?.uid
  const userData = store.getState().auth.userData
  push(ref(database, path), {
    qna,
    profileImage,
    userName,
    userData,
    modalId: id,
    timestamp: serverTimestamp(),
    userId,
    from,
    votes: 0
  }).then(() => {
    if (cb)
      cb()
  })
}

// get list of qna with listener from firebase realtime database
export function getQnaListWithListener(path, cb) {
  const qnaRef = ref(database, path);
  onChildAdded(query(qnaRef), (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key });
    }
  })
  onChildChanged(query(qnaRef), (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, changed: true });
    }
  })
  onChildRemoved(query(qnaRef, orderByChild('timestamp')), (data) => {
    if (cb) {
      cb({ ...data.val(), isRemoved: true, id: data.key });
    }
  })
}

export function saveQnaUpVotes(qnaPath, qnaResponsePath, userId, qna, activeScenePath, cb) {
  const path = `${qnaPath}/${qna.id}`
  const responsePath = `${qnaResponsePath}/${userId}/${qna.id}`
  const qnaRef = ref(database, path);
  const userQnaResponseRef = ref(database, responsePath);

  update(qnaRef, {
    votes: increment(1)
  }).then(() => {
    // if(activeScenePath) {
    //   const activeSceneQnaRef = ref(database, activeScenePath);
    //   update(activeSceneQnaRef, {
    //     votes: increment(1)
    //   })
    // }
    if (cb)
      cb()
  });
  set(userQnaResponseRef, { timestamp: serverTimestamp() });
}

export function saveQnaDownVotes(qnaPath, qnaResponsePath, userId, qna, activeScenePath, cb) {
  const path = `${qnaPath}/${qna.id}`
  const responsePath = `${qnaResponsePath}/${userId}/${qna.id}`
  const qnaRef = ref(database, path);
  const userQnaResponseRef = ref(database, responsePath);

  update(qnaRef, {
    votes: increment(-1)
  }).then(() => {
    // if(activeScenePath) {
    //   const activeSceneQnaRef = ref(database, activeScenePath);
    //   update(activeSceneQnaRef, {
    //     votes: increment(-1)
    //   })
    // }
    if (cb)
      cb()
  });
  set(userQnaResponseRef, { timestamp: serverTimestamp() });
}

export async function hasUserRespondedToUpVote(path) {
  const res = await get(ref(database, path));
  return !!res.val()
}

// get list of polls with listener from firebase realtime database
export function getPollsListWithListener(path, cb) {
  const pollsRef = ref(database, path);
  const childAdded = onChildAdded(query(pollsRef), (data) => {
    // if (cb) {
    //   cb({ ...data.val(), id: data.key });
    // }
    get(pollsRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val() });
      }
    }).catch((error) => {
      console.error(error);
    });
  })
  const childChanged = onChildChanged(query(pollsRef), (data) => {
    // if (cb) {
    //   cb({ ...data.val(), id: data.key, changed: true });
    // }
    get(pollsRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val() });
      }
    }).catch((error) => {
      console.error(error);
    });
  })

  return { childAdded, childChanged }
}

// save poll in firebase realtime DB
export function savePoll(path, poll, cb) {
  const userName = store.getState().auth.userData?.name
  const userId = store.getState().auth.userData?.uid
  push(ref(database, path), {
    ...poll,
    createByName: userName,
    timestamp: serverTimestamp(),
    createdById: userId,
    votes: 0
  }).then(() => {
    if (cb)
      cb()
  })
}

// save poll in firebase realtime DB
export async function savePollAnswer(eventId, stageId, poll, answerIndex, cb) {
  const userData = store.getState().auth.userData
  const userId = store.getState().auth.userData?.uid
  const answerId = poll.options[answerIndex].id;

  const pollPath = `${eventId}/polls/${stageId}/${poll.id}`
  const answerPath = `${eventId}/polls/${stageId}/${poll.id}/options/${answerIndex}`
  const userPollResponsePath = `${eventId}/poll-responses/${stageId}/${poll.id}/${userId}`

  const pollRef = ref(database, pollPath);
  const answerRef = ref(database, answerPath);
  const userPollResponseRef = ref(database, userPollResponsePath);


  update(pollRef, {
    votes: increment(1),
    changedThrough: poll.changedThrough,
    isUserAnswered: poll.isUserAnswered,
    selectedOptionId: poll.selectedOptionId
  }).then(() => {
    if (cb)
      cb()
  });
  update(answerRef, {
    votes: increment(1)
  }).then(() => {
    if (cb)
      cb()
  });

  const answerData = await get(answerRef);
  const value = answerData.val().value;
  set(userPollResponseRef, { answerId, userData, value, responseTime: serverTimestamp() });
}

export function stopPoll(eventId, stageId, poll, cb) {

  const pollPath = `${eventId}/polls/${stageId}/${poll.id}`
  const pollRef = ref(database, pollPath);

  update(pollRef, {
    status: "Closed",
    changedThrough: poll.changedThrough
  }).then(() => {
    if (cb)
      cb()
  });
}

export async function hasUserRespondedToPoll(path) {
  const res = await get(ref(database, path));
  return res.val() ? true : false
}

//function for assigned speakers to go to back stage - this function saves speaker data in a collection for backstage speakers on firebase
export function joinBackStageForSpeaker(path, data, callback) {
  const k = push(ref(database, path), data);
  const dataKey = k.key;
  onDisconnect(ref(database, `${path}/${dataKey}`)).remove();
  if (callback) {
    callback(`${path}/${dataKey}`)
  }
}

export function joinBackStageRequestForAudience(path, data, cb) {
  const k = set(ref(database, path), { ...data, timestamp: serverTimestamp() });
  const dataKey = k.key;
  onDisconnect(ref(database, `${path}/${dataKey}`)).remove();

  if (cb) {
    cb({ ...data, id: dataKey });
  }
}

export function removeAttendeeFromPath(path) {
  onDisconnect(ref(database, path)).remove();
}

// get list of speakers with listener from firebase realtime database for organizer
export function getSpeakersWithListenerForOrganizer(path, cb) {
  const pathRef = ref(database, path);
  const queryRef = query(pathRef);
  const childAdded = onChildAdded(queryRef, (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key });
    }
  })

  // onChildChanged(query(pathRef), (data) => {
  //   if (cb) {
  //     cb({ ...data.val(), id: data.key, changed: true });
  //   }
  // })
  const childRemoved = onChildRemoved(queryRef, (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, removed: true });
    }
  })
  return { childAdded, childRemoved };
}

export function removeFromSpeakersListFirebase(path, cb) {
  // const pathRef = ref(database, path);
  // const queryRef = query(pathRef);
  try {
    remove(ref(database, path));
    if (typeof cb === 'function') {
      cb()
    }
  } catch (error) {

  }
}

export function moveDataFromOnePathToAnother(fromPath, toPath, shouldAttachDisconnectListener) {
  const dbRef = ref(database);
  get(child(dbRef, fromPath)).then((snapshot) => {
    if (snapshot.exists()) {
      set(ref(database, toPath), snapshot.val()).then((k) => {
        remove(ref(database, fromPath)).then(() => { });
        if (shouldAttachDisconnectListener) {
          onDisconnect(ref(database, toPath)).remove();
        }
      })
    }
  }).catch((error) => {
    console.error(error);
  });
}

export function copyDataFromOnePathToAnother(fromPath, toPath) {
  const dbRef = ref(database);
  get(child(dbRef, fromPath)).then((snapshot) => {
    if (snapshot.exists()) {
      set(ref(database, toPath), snapshot.val()).then(() => { })
    }
  }).catch((error) => {
    console.error(error);
  });
}

// save room in firebase realtime DB
export function saveRoomChair(path, cb) {
  const userName = store.getState().auth.userProfileData?.name
  const userId = store.getState().auth.userProfileData?.uid
  const userProfileImage = store.getState().auth.userProfileData?.profile_image_url
  const userDesignation = store.getState().auth.userProfileData?.designation
  const userCompany = store.getState().auth.userProfileData?.company
  // push(ref(database, path), {
  set(ref(database, path + "/" + userId), {
    userName,
    timestamp: serverTimestamp(),
    userId,
    userProfileImage,
    userDesignation,
    userCompany
  }).then((data) => {
    // onDisconnect(ref(database, path + "/" + data.key)).remove()
    onDisconnect(ref(database, path + "/" + userId)).remove()
    if (cb)
      // cb(data.key)
      cb(userId)
  })
}

// remove chair from db with listener from firebase
export function removeRoomChair(path, rowId, cb) {
  const roomRef = ref(database, path);
  const userId = store.getState().auth.userData?.uid
  // remove(child(roomRef, rowId)).then((data) => {
  remove(child(roomRef, userId)).then((data) => {
    if (cb)
      cb(data)
  })
}

// get list of chair with room from firebase realtime database
export function getRoomsChairListListener(path, cb) {
  const roomRef = ref(database, path);
  onChildAdded(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val() });
      }
    }).catch((error) => {
      console.error(error);
    });

    /*if (cb) {
      cb({ data: data.val(), id: data.key });
    }*/
  });
  onChildChanged(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val() });
      }
    }).catch((error) => {
      console.error(error);
    });
    /*if (cb) {
      cb({ data: data.val(), id: data.key, changed: true});
    }*/
  });
}

export function getDeleteRoomChairListener(path, callback) {
  const roomRef = ref(database, path);
  onChildRemoved(query(roomRef), (data) => {
    // const key = Object.keys(data.val()).find(e => true);
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        callback({ data: snapshot.val() });
      } else {
        callback({ data: {} });
      }
    }).catch((error) => {
      console.error(error);
    });
    /*if (callback) {
      callback({ ...data.val(), id: data.key, changed: true, key: key  });
    }*/
  });
}

export function getListFromPath(path, cb) {
  const dataRef = ref(database, path);
  get(dataRef).then((snapshot) => {
    if (snapshot.exists()) {
      cb({ data: snapshot.val() });
    }
  }).catch((error) => {
    console.error(error);
  });
}

export const createOnDisconnectRemoveCallBack = (path) => {
  onDisconnect(ref(database, path)).remove()
  return
}

export function joinExpoRequest(path, data, callback) {
  const k = push(ref(database, path), data);
  const dataKey = k.key;
  if(data.isBoothOwner) {
    onDisconnect(ref(database, `${path}/${dataKey}`)).remove();
  }
  if (callback) {
    callback(`${path}/${dataKey}`)
  }
}

  // function for join expo
  export function joinExpo(path, data, callback) {
    // Check if data already exists in the database
    const databaseRef = ref(database, path);
    const query = orderByChild(databaseRef, "name").equalTo(data.name);
    get(query).then((snapshot) => {
      if (snapshot.exists()) {
        // Data already exists, do not add it again
        console.log("Data already exists in database");
        return;
      } else {
        // Data does not exist, add it to the database
        const k = push(databaseRef, data);
        const dataKey = k.key;
        if(data.isBoothOwner) {
          onDisconnect(ref(database, `${path}/${dataKey}`)).remove();
        }
        if (callback) {
          callback(`${path}/${dataKey}`)
        }
      }
    });
  }

// save expo visitor in firebase realtime DB
export function saveExpoVisitor(path, userId, userName, designation, company, profileImageUrl, cb) {
  set(ref(database, path), {
    uid: userId,
    name: userName,
    designation: designation,
    company: company,
    profile_image_url: profileImageUrl,
    visitCount: increment(1),
    timestamp: serverTimestamp(),
  }).then(() => {
    if (cb)
      cb()
  })
}

export function saveExpoVisitorCount(expoVisitorqnaPath, expoVisitorCountPath, userId, qna, cb) {
  const path = `${expoVisitorqnaPath}/${qna.id}`
  const visitorRef = ref(database, path);

  const visitorCountPath = `${expoVisitorCountPath}/${userId}/${qna.id}`
  const visitorCountRef = ref(database, visitorCountPath);

  update(visitorRef, {
    visitCount: increment(1)
  }).then(() => {
    if (cb)
      cb()
  });
  set(visitorCountRef, { timestamp: serverTimestamp() });
}

export function removeUserFromLocation(path) {
  remove(ref(database, path));
}

// get list of visitors in expo from firebase realtime database
export function getExpoVisitorListListener(path, callback) {
  const roomRef = ref(database, path);
  const childAdded = onChildAdded(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        callback({ data: snapshot.val(), event: "onChildAdded" });
      }
    }).catch((error) => {
      console.error(error);
    });
  })


  const childChanged = onChildChanged(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        callback({ data: snapshot.val(), event: "onChildChanged" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  const childRemoved = onChildRemoved(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        callback({ data: snapshot.val(), event: "onChildRemoved" });
      } else {
        callback({ data: {}, event: "onChildRemoved" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  return { childAdded, childChanged, childRemoved }
}

// get list of booth owner in expo from firebase realtime database
export function getExpoBoothOwnerListListener(path, callback) {
  const roomRef = ref(database, path);
  const childAdded = onChildAdded(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        callback({ data: snapshot.val(), event: "onChildAdded" });
      }
    }).catch((error) => {
      console.error(error);
    });
  })
  const childChanged = onChildChanged(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        callback({ data: snapshot.val(), event: "onChildChanged" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  const childRemoved = onChildRemoved(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        callback({ data: snapshot.val(), event: "onChildRemoved" });
      } else {
        callback({ data: {}, event: "onChildRemoved" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  return { childAdded, childChanged, childRemoved }
}


export function updateUserRequestData(path, data, callback) {
  update(ref(database, path), data).then(() => {
    callback()
  })
}

/**
 * function to start public broadcast of a stage event
 * this will save stage id in main event notifications collection which will be subscribed by audience
 */
export function startPublicBroadcast(eventId, stageId, sessionId, message, isRecordingStarted, serverSessionTimestamp) {

  const path = `${eventId}/notifications/${notificationCategoryTypes.BROADCAST}`

  push(ref(database, path), {
    global: true,
    type: notificationTypes.STAGE_BROADCAST_STARTED,
    timestamp: new Date().getTime(),
    // stageId: stageId,
    stageId: sessionId,
    sessionId: sessionId,
    deliveryType: notificationDeliveryTypes.SILENT,
    message: message,
    isRecordingStarted: isRecordingStarted,
    category: notificationCategoryTypes.BROADCAST,
    sessionStartTimestamp: serverSessionTimestamp,
  })
}

export function endSessionBroadcast(eventId, stageId, sessionId, message) {

  const path = `${eventId}/notifications/${notificationCategoryTypes.SESSION_BROADCAST_ENDED}`

  push(ref(database, path), {
    global: true,
    type: notificationTypes.STAGE_SESSION_BROADCAST_ENDED,
    timestamp: new Date().getTime(),
    // stageId: stageId,
    stageId: sessionId,
    sessionId: sessionId,
    deliveryType: notificationDeliveryTypes.SILENT,
    message: message
  })
}

/**
 * function to end public broadcast of a stage event
 * this will save stage id in main event notifications collection which will be subscribed by audience
 */
export function endPublicBroadcast(eventId, stageId, sessionId, message) {

  const path = `${eventId}/notifications/${notificationCategoryTypes.BROADCAST}`

  push(ref(database, path), {
    global: true,
    type: notificationTypes.STAGE_BROADCAST_ENDED,
    timestamp: new Date().getTime(),
    // stageId: stageId,
    stageId: sessionId,
    sessionId: sessionId,
    deliveryType: notificationDeliveryTypes.SILENT,
    message: message
  })
}


/**
 * get event notifications with listener
 */
export function getNotificationsListWithListener(path, cb) {
  const notificationsRef = ref(database, path);
  const childAdded = onChildAdded(query(notificationsRef), (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key });
    }
  });

  const childRemoved = onChildRemoved(query(notificationsRef), (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, removed: true });
    }
  });

  const childChanged = onChildChanged(query(notificationsRef), (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, changed: true });
    }
  });

  return { childAdded, childRemoved, childChanged }
}

export function getNotificationsListWithListenerForSpeakers(path, cb) {
  const notificationsRef = ref(database, path);
  const childChanged = onChildChanged(query(notificationsRef), (data) => {
    if (cb) {
      get(notificationsRef).then((snapshot) => {
        if (snapshot.exists()) {
          cb({ data: snapshot.val(), event: "onChildChanged" });
        }
      }).catch((error) => {
        console.error(error);
      });
    }
  });

  return { childChanged }
}


export const deleteDocument = async (path) => {
  await remove(ref(database, path));
  return
}

// get list of speakers with listener from firebase realtime database for organizer
export function getAudienceBackStageRequestsListWithListenerForOrganizer(path, cb) {
  const pathRef = ref(database, path);
  const queryRef = query(pathRef);
  const childAdded = onChildAdded(queryRef, (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, added: true });
    }
  })

  const childChanged = onChildChanged(queryRef, (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, changed: true });
    }
  })
  const childRemoved = onChildRemoved(queryRef, (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, removed: true });
    }
  })
  return { childAdded, childRemoved, childChanged };
}

//generic function to read data from a path once
export async function getDataFromPath(path) {
  let reference = await ref(database, path);
  const res = await get(reference);
  return { ...res.val(), id: res.key };
}

//generic function to write data to a path
export async function setDataToPath(path, data, callback) {
  const res = await set(ref(database, path), { ...data, timestamp: serverTimestamp() });
  if (callback) {
    callback()
  }
  return res;
}

//generic function to push data to a path
export async function pushDataToPath(path, data, callback) {
  const res = await push(ref(database, path), { ...data, timestamp: serverTimestamp() });
  if (callback) {
    callback()
  }
  return res;
}

//generic function to remove data from a path
export async function removeDataFromPath(path) {
  const res = await remove(ref(database, path));
  return res;
}

//generic function to update data on a path
export async function updateDataOnPath(path, data, cb) {
  const res = await update(ref(database, path), data);
  if (cb) {
    cb(res)
  }
  return res;
}

//generic function to create change listener on a path
export async function createChangeListenerOnPath(path, cb) {
  const childChanged = onChildChanged(ref(database, path), (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, changed: true });
    }
  })
  return childChanged;
}

//generic function to create removed listener on a path
export async function createRemoveListenerOnPath(path, cb) {
  const childChanged = onChildRemoved(ref(database, path), (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, removed: true });
    }
  })
  return childChanged;
}

export function stageFirebaseEventListner(path, cb) {
  const roomRef = ref(database, path);
  const childAdded = onChildAdded(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childAdded" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
  const childChanged = onChildChanged(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val() });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
  const childRemoved = onChildRemoved(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childRemoved" });
      } else {
        cb({ data: {}, event: "childRemoved" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  return { childAdded, childChanged, childRemoved };
}

export function addStageMedia(path, cb) {
  const roomRef = ref(database, path);
  const childAdded = onChildAdded(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childAdded" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  const childChanged = onChildChanged(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childChanged" });
      } else {
        cb({ data: {}, event: "childChanged" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  const childRemoved = onChildRemoved(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childRemoved" });
      } else {
        cb({ data: {}, event: "childRemoved" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  return { childAdded, childChanged, childRemoved };
}



// save staged user in firebase realtime DB
export function saveStagedUser(userData, path, cb) {
  set(ref(database, path), {
    ...userData,
    timestamp: serverTimestamp(),
  }).then(() => {
    onDisconnect(ref(database, `${path}`)).remove();
    if (cb)
      cb()
  })
}

// save staged user in firebase realtime DB
export function saveEventLiveUser(userData, path, cb) {
  set(ref(database, path), {
    ...userData,
    timestamp: serverTimestamp(),
  }).then(() => {
    onDisconnect(ref(database, `${path}`)).remove();
    if (cb)
      cb()
  })
}

// get list of stage user with listener from firebase
export function getStageUserListWithListener(path, cb) {

  const stageUserRef = ref(database, path);

  onChildAdded(query(stageUserRef, orderByChild('timestamp')), (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, added: true });
    }
  });

  onChildRemoved(stageUserRef, (data) => {
    if (cb) {
      cb({ ...data.val(), id: data.key, removed: true });
    }
  });
}


// get list of all data by path from firebase
export function getAllDataByPath(path, cb) {
  getDataFromPath(path).then((data) => {
    if (cb) {
      cb(data)
    }
  }).catch((reason) => {
  })
}


// get all live pvt chat list

export function allLivePvtChat(path, cb) {
  const roomRef = ref(database, path);
  const childAdded = onChildAdded(query(roomRef), (data) => {
    // alert("onChildAdded")
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childAdded" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
  const childChanged = onChildChanged(query(roomRef), (data) => {
    // alert("onChildChanged")
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val() });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
  const childRemoved = onChildRemoved(query(roomRef), (data) => {
    // alert("onChildRemoved")
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childRemoved" });
      } else {
        cb({ data: {}, event: "childRemoved" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  return {
    childAdded,
    childChanged,
    childRemoved
  };
}



// get all notification list

export function allNotification(path, cb) {
  const roomRef = ref(database, path);
  const childAdded = onChildAdded(query(roomRef), (data) => {
    // alert("onChildAdded")
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childAdded" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
  const childChanged = onChildChanged(query(roomRef), (data) => {
    // alert("onChildChanged")
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childChanged" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
  const childRemoved = onChildRemoved(query(roomRef), (data) => {
    // alert("onChildRemoved")
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childRemoved" });
      } else {
        cb({ data: {}, event: "childRemoved" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });

  return {
    childAdded,
    childChanged,
    childRemoved
  };
}

export const onUpdateBackStagePermission = (path, cb) => {
  const roomRef = ref(database, path);
  const childAdded = onChildAdded(query(roomRef), (data) => {
    // alert("onChildAdded")
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val(), event: "childAdded" });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
  const childChanged = onChildChanged(query(roomRef), (data) => {
    get(roomRef).then((snapshot) => {
      if (snapshot.exists()) {
        cb({ data: snapshot.val() });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
  return {
    childAdded, childChanged
  };
}
