import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    feedbackRes:{},
    feedBackData: {},
    isFeedBackSubmitted: false,
    feedBackText: "",
    active: false,
    tags:[]
};


export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setFeedBackRes: (state, action) => {
            const { payload } = action;
            state.feedbackRes = payload;
        },
        setFeedBackData: (state, action) => {
            const { payload } = action;
            state.feedBackData = payload;
        },
        setIsFeedBackSubmitted: (state, action) => {
            const { payload } = action;
            state.isFeedBackSubmitted = payload
        },
        setFeedBackText: (state, action) => {
            const { payload } = action;
            state.feedBackText = payload;
        },
        setApiCall: (state, action) => {
            state.active = true;
        },
        setTags: (state, action) => {
            const { payload } = action;
            state.tags = payload;
        }
    },
});

export const feedbackAction = feedbackSlice.actions;

export default feedbackSlice.reducer;
