import { actionTypes } from '../actionTypes';

export const getNotification = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.GET_NOTIFICATION,
        payload: value
    });
}

export const onSeenNotification = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.ON_SEEN_NOTIFICATION,
        payload: value
    });
}