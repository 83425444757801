import { actionTypes } from '../actionTypes';

const initialState = {
    notification: { total_unread: 0, data: [] },
    notificationSeen: {
        isSeen: false, count: 0
    }
};


export default function notificationReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case actionTypes.GET_NOTIFICATION: {
            return { ...state, notification: payload };
        }

        case actionTypes.ON_SEEN_NOTIFICATION: {
            return { ...state, notificationSeen: payload };
        }
        default:
            return state;
    }
}
